import React from 'react';
import '@progress/kendo-theme-default/dist/all.css';
import Head from '../../components/layout/Head';
import Layout from '../../components/layout/Layout';
import AuthWrapper from '../../components/layout/AuthWrapper';
import BuildFormContainer from '../../components/organisms/build-form/BuildFormContainer';

const BuildFormPage = () => {
  return (
    <AuthWrapper>
      <Head title="Build Form" />
      <Layout
        section="templates"
        breadcrumbs={[
          { link: '/list-forms', label: 'Form Templates' },
          { link: '/choose-form-type', label: 'Form Workflows' },
          { label: 'Create Form' },
        ]}
      >
        <BuildFormContainer />
      </Layout>
    </AuthWrapper>
  );
};

export default BuildFormPage;
