import produce from 'immer';
import React, { useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import {
  IFormField,
  IFormFieldPrePop,
  IFormImage,
  IFormTable,
  IFormText,
} from '../../../context-providers/forms/forms-fields';
import {
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import { getQueryVar } from '../../../utils/query-vars';
import {
  IconBin,
  IconChevronCollapse,
  IconChevronExpand,
  IconChevronsLeft,
  IconEdit,
  IconLightning,
} from '../../icons';
import { WordWrapBox } from '../../layout/Layout.style';
import FormField from '../Form/FormField';
import FormText from '../Form/FormText';

import {
  BuildFormField,
  FormFieldActions,
  FormFieldButton,
  FormFields,
} from './BuildFormItem.style';
import {
  IFormTemplate,
  IWorkflow,
} from '../../../context-providers/forms/forms-reducer';
import StaticTooltipPrePopulated from '../static-tooltip-pre-populated';
import {
  ILearnerEthnicity,
  ILearnerLLDD,
  ILearnerSex,
} from '../../../context-providers/learner/learner-reducer';
import FormWorkflowUtil from '../../../utils/form-workflow-util';
import { StageId } from '../../../context-providers/forms/form-workflows';
import AddFormFieldPrePop from '../../modals/forms/AddFormFieldPrePop';
import AddDataTable from '../../modals/data-tables/AddDataTable';
import AddFormField from '../../modals/forms/AddFormField';
import AddFormText from '../../modals/forms/AddFormText';
import ConfirmDeleteField from '../../modals/forms/ConfirmDeleteField';

interface IBuildFormItem {
  index: number;
  field: IFormField | IFormText | IFormImage | IFormTable | IFormFieldPrePop;
  inputOnlyForm: IFormTemplate;
  sexOptions?: ILearnerSex[] | undefined;
  llddOptions?: ILearnerLLDD[] | undefined;
  ethnicityOptions?: ILearnerEthnicity[] | undefined;
}

const BuildFormItem = ({
  index,
  field,
  inputOnlyForm,
  sexOptions,
  llddOptions,
  ethnicityOptions,
}: IBuildFormItem) => {
  const workflowId = getQueryVar('workflowId');
  const showModal = useShowModal();
  const newForm = useNewForm();
  const setNewForm = useSetNewForm();
  const [workflow, setWorkflow] = useState<IWorkflow>();
  const [showEmployerSection, setShowEmployerSection] =
    useState<boolean>(false);
  const [showLearnerSection, setShowLearnerSection] = useState<boolean>(false);

  const getWorkflow = () => {
    if (!workflowId) return;
    FormWorkflowUtil.getWorkflow(workflowId).then((workflow) => {
      setWorkflow(workflow);
      setShowEmployerSection(
        workflow.stages.findIndex((x) => x.stageId === StageId.EMPLOYER) > -1,
      );
      setShowLearnerSection(
        workflow.stages.findIndex((x) => x.stageId === StageId.LEARNER) > -1,
      );
    });
  };

  const optionsArray =
    field.inputType === 'Sex'
      ? sexOptions
      : field.inputType === 'Ethnicity'
      ? ethnicityOptions
      : field.inputType === 'LLDD'
      ? llddOptions
      : [];

  useEffect(() => {
    getWorkflow();
  }, []);

  const onConfirmDelete = (
    deleteField: IFormField | IFormImage | IFormText | IFormFieldPrePop,
  ) => {
    const form = produce(newForm, (d) => {
      const i = d.formData.findIndex((f) => f.id === deleteField.id);
      d.formData.splice(i, 1);
      if (deleteField.itemType === 'image') {
        fetchAPI({
          path: 'files/deleteformfile',
          method: 'DELETE',
          responseType: 'text',
          urlParams: {
            fileId: deleteField.props.fileId,
          },
        }).finally();
      }
      return d;
    });
    setNewForm(form);
  };

  const moveItem = (from: number, to: number) => {
    const addTo = newForm.formData.filter((f) => f.itemType === 'image');
    const form = produce(inputOnlyForm, (d) => {
      d.formData.splice(to, 0, d.formData.splice(from, 1)[0]);
      addTo ? d.formData.push(...addTo) : null;
      return d;
    });
    setNewForm(form);
  };

  const lastIndex =
    newForm.formData.filter((f) => f.inputType !== 'Footer').length - 1;

  const prepopTooltip =
    "The data entered in this field is saved to the form recipient's record and pre-populates each form.";

  return (
    <BuildFormField
      isTextField={
        field.itemType === 'text' ||
        field.inputType === 'DataTable' ||
        field.itemType === 'prepop'
      }
    >
      <FormFieldActions>
        <FormFieldButton
          disabled={index === 0}
          data-qa="form-btn-move-top"
          onClick={() => moveItem(index, 0)}
          title="Move to top"
        >
          <IconChevronsLeft rotate={90} />
          <span className="sr-only">Move to top</span>
        </FormFieldButton>

        <FormFieldButton
          disabled={index === 0}
          data-qa="form-btn-move-up"
          onClick={() => moveItem(index, index - 1)}
          title="Move up"
        >
          <IconChevronCollapse />
          <span className="sr-only">Move up</span>
        </FormFieldButton>

        <FormFieldButton
          disabled={index === lastIndex}
          data-qa="form-btn-move-down"
          onClick={() => moveItem(index, index + 1)}
          title="Move down"
        >
          <IconChevronExpand />
          <span className="sr-only">Move down</span>
        </FormFieldButton>

        <FormFieldButton
          disabled={index === lastIndex}
          data-qa="form-btn-move-bottom"
          onClick={() => moveItem(index, lastIndex)}
          title="Move to bottom"
        >
          <IconChevronsLeft rotate={270} />
          <span className="sr-only">Move to bottom</span>
        </FormFieldButton>

        <FormFieldButton
          title="Edit"
          data-qa="form-btn-edit-field"
          onClick={() => {
            if (field.itemType === 'prepop') {
              showModal(AddFormFieldPrePop, {
                currentField: field as IFormFieldPrePop,
                index,
                options: optionsArray,
                hasLearnerStage: showLearnerSection,
                hasEmployerStage: showEmployerSection,
                isDefault: field.default,
              });
            } else if (field.inputType === 'DataTable') {
              showModal(AddDataTable, {
                currentTable: field as IFormTable,
                index,
                workflow: workflow!,
              });
            } else if (field.itemType === 'input') {
              showModal(AddFormField, {
                currentField: field as IFormField,
                index,
                workflow: workflow!,
              });
            } else {
              showModal(AddFormText, {
                currentField: field as IFormText,
                index,
                workflow: workflow!,
              });
            }
          }}
        >
          <IconEdit />
        </FormFieldButton>
        <FormFieldButton
          title="Delete"
          disabled={field.default}
          data-qa="form-btn-delete-field"
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Build form - delete field',
            });
            showModal(ConfirmDeleteField, {
              onConfirmDelete: () => {
                onConfirmDelete(field);
              },
            });
          }}
        >
          <IconBin />
        </FormFieldButton>
      </FormFieldActions>
      {field.itemType === 'text' ? (
        <FormText field={field} data-qa={`form-lbl-${field.inputType}`} />
      ) : (
        <WordWrapBox>
          {field.itemType === 'prepop' && (
            <StaticTooltipPrePopulated text={prepopTooltip}>
              <IconLightning />
            </StaticTooltipPrePopulated>
          )}
          <FormFields isDataTable={field.inputType === 'DataTable'}>
            <FormField
              disabled
              dataQa={`form-input-${field.inputType}`}
              userType="builder"
              reviewing
              index={index}
              field={field}
              sexOptions={sexOptions}
              llddOptions={llddOptions}
              ethnicityOptions={ethnicityOptions}
            />
          </FormFields>
        </WordWrapBox>
      )}
    </BuildFormField>
  );
};

export default BuildFormItem;
