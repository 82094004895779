import React from 'react';
import ReactGA from '../../../utils/google-analytics';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import Icon from '../../icons/Icon';
import { FormFieldButtons } from './CreateFormField.style';
import {
  blankInputs,
  TFormDataTableSpec,
  TFormFieldSpec,
  TFormInsertSpec,
  TFormPrePopFieldSpec,
  TFormTextSpec,
} from '../../../context-providers/forms/forms-fields';
import Stack from '../../atoms/stack/Stack';
import { useNewForm } from '../../../context-providers/forms/forms-hooks';
import { validateMaxFields } from '../../../context-providers/forms/forms-validation';
import { IWorkflow } from '../../../context-providers/forms/forms-reducer';
import AddFormField from '../../modals/forms/AddFormField';
import AddDataTable from '../../modals/data-tables/AddDataTable';
import AddFormText from '../../modals/forms/AddFormText';

const FieldsBlank = ({
  workflow,
  isEnquiry,
}: {
  workflow: IWorkflow;
  isEnquiry: boolean;
}) => {
  const showModal = useShowModal();
  const newForm = useNewForm();
  const hasMaxFields = !validateMaxFields(newForm);

  const handleFormFieldClick = (
    item:
      | TFormFieldSpec
      | TFormTextSpec
      | TFormInsertSpec
      | TFormDataTableSpec
      | TFormPrePopFieldSpec,
  ) => {
    if (item.itemType === 'input') {
      showModal(AddFormField, {
        formFieldSpec: item,
        workflow,
      });
    } else if (item.type === 'DataTable') {
      showModal(AddDataTable, {
        workflow,
      });
    } else {
      showModal(AddFormText, {
        formFieldSpec: item as TFormTextSpec,
        workflow,
      });
    }
  };

  return (
    <Stack stackMultiplier={2}>
      <Stack stackMultiplier={1}>
        <p>
          Create a new field from scratch. Select from the commonly used input
          types below.
        </p>
      </Stack>

      <FormFieldButtons>
        {blankInputs.map((item, i) => {
          return (
            <button
              type="button"
              data-qa={`form-btn-${item.type}`}
              key={`${item.type}-${i}`}
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: `Build form - ${item.label}`,
                });
                handleFormFieldClick(item);
              }}
              disabled={item.isDisabled || hasMaxFields}
            >
              <Icon type={item.icon} />
              <span>{item.label}</span>
            </button>
          );
        })}
      </FormFieldButtons>
    </Stack>
  );
};

export default FieldsBlank;
