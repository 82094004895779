import produce from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  TFormFieldSpec,
  IFormField,
  getFieldTitle,
} from '../../../context-providers/forms/forms-fields';
import {
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Checkbox from '../../atoms/form-fields/checkbox/Checkbox';
import Label from '../../atoms/form-fields/label/Label';
import Radio from '../../atoms/form-fields/radio/Radio';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { IconArrowRight } from '../../icons';
import { ModalText, RadioWrapper } from '../ModalContent.style';
import { StageId } from '../../../context-providers/forms/form-workflows';
import { IWorkflow } from '../../../context-providers/forms/forms-reducer';
import { TFormItemCompletedBy } from '../../../context-providers/forms/form-types';
import RichTextInput from '../../atoms/form-fields/rich-text-input/RichTextInput';

interface IAddFormField {
  formFieldSpec?: TFormFieldSpec;
  workflow: IWorkflow;
  currentField?: IFormField;
  index?: number;
}

const AddFormField = ({
  formFieldSpec,
  workflow,
  currentField,
  index,
}: IAddFormField) => {
  const closeModal = useCloseModal();
  const newForm = useNewForm();
  const setNewForm = useSetNewForm();
  const [step, setStep] = useState(0);
  const isHyperlink =
    formFieldSpec?.type === 'Hyperlink' ||
    currentField?.inputType === 'Hyperlink';
  const isRequired: boolean = isHyperlink ? false : true;
  const [field, setField] = useState<IFormField>(
    currentField || {
      id: uuidv4(),
      itemType: 'input',
      inputType: formFieldSpec.type,
      isSensitive: false,
      completedBy: 'learner',
      props: {
        label: '',
        description: '',
        options: formFieldSpec.hasOptions ? [] : undefined,
      },
      validation: {
        required: isRequired,
      },
    },
  );
  const [hasLearnerStage, setHasLearnerStage] = useState<boolean>(false);
  const [hasEmployerStage, setHasEmployerStage] = useState<boolean>(false);
  const [hasProviderStage, setHasProviderStage] = useState<boolean>(false);
  const [hasMoreThenOneStage, setHasMoreThenOneStage] =
    useState<boolean>(false);

  const setPermissions = () => {
    const a = !!workflow.stages.find((x) => x.stageId === StageId.LEARNER);
    const b = !!workflow.stages.find((x) => x.stageId === StageId.EMPLOYER);
    const c = !!workflow.stages.find((x) => x.stageId === StageId.PROVIDER);
    const x = (a && b) || (a && c) || (b && c);
    setHasLearnerStage(a);
    setHasEmployerStage(b);
    setHasProviderStage(c);
    setHasMoreThenOneStage(x);
    if (!x) {
      let value: TFormItemCompletedBy = 'learner';
      if (b) value = 'employer';
      if (c) value = 'trainingProvider';
      handleChange(value, 'completedBy');
    }
  };

  useEffect(() => {
    setPermissions();
  }, []);

  const handleChange = (
    value: string[] | string | boolean,
    fieldName: string,
  ) => {
    setField(
      produce(field, (newField) => {
        set(newField, fieldName, value);
        return newField;
      }),
    );
  };

  const isSingleCheckbox = field.inputType === 'Checkbox';

  const optionsVal = field.props.options ? field.props.options.join('\n') : '';
  const handleOptionsChange = (value: string, fieldName: string) => {
    handleChange(value.split(/\r?\n/), fieldName);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    let currentFormData = newForm.formData.slice();
    const footerIndex = currentFormData.findIndex(
      (f) => f.inputType === 'Footer',
    );
    const itemIndex = currentFormData.findIndex(
      (f) => f.id === currentField?.id,
    );
    const form = produce(newForm, (d) => {
      if (currentField && index !== undefined) {
        currentFormData.splice(itemIndex, 1, field);
      } else {
        if (footerIndex < 0) {
          currentFormData.push(field);
        } else {
          currentFormData.splice(footerIndex, 0, field);
        }
      }
      d.formData = currentFormData;
      return d;
    });
    setNewForm(form);
    closeModal();
  };

  const labelErr = validateField(field.props.label, {
    required: true,
    title: true,
    hyperlink: isHyperlink,
  });
  const descriptionErr = validateField(field.props.description, {
    description: true,
  });

  const hasOptions = !!field.props.options;
  const optionsErr = hasOptions
    ? validateField(optionsVal, { options: true })
    : '';
  const step1Valid = !labelErr && !optionsErr && !descriptionErr;

  const actionType = currentField ? 'Edit' : 'Create';

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
    >
      <Stack stackMultiplier={2}>
        {step === 0 && (
          <>
            <h2 data-qa={`create-lbl-${field.inputType}`}>
              {actionType} {getFieldTitle(field.inputType)}
            </h2>
            <Stack stackMultiplier={1}>
              {!isHyperlink && (
                <RichTextInput
                  as={'html-title'}
                  label="Title"
                  id="title"
                  value={field.props.label || ''}
                  error={labelErr}
                  onChangeText={(str) => handleChange(str, 'props.label')}
                  dataQa="create-input-title"
                ></RichTextInput>
              )}
              {isHyperlink && (
                <TextInput
                  as={'input'}
                  label="Hyperlink"
                  id="hyperlink"
                  description="Enter the URL of the external website here"
                  value={field.props.label || ''}
                  error={labelErr}
                  onChangeText={(str) => handleChange(str, 'props.label')}
                  dataQa="create-input-hyperlink"
                ></TextInput>
              )}

              <RichTextInput
                as={'html-description'}
                label="Description"
                id="description"
                description={
                  isHyperlink
                    ? 'This text will appear above the hyperlink (Optional)'
                    : 'This is the text that appears under the title (Optional)'
                }
                value={field.props.description || ''}
                error={descriptionErr}
                onChangeText={(str) => handleChange(str, 'props.description')}
                dataQa="create-input-desc"
              ></RichTextInput>
              {hasOptions && (
                <TextInput
                  as={isSingleCheckbox ? 'input' : 'textarea'}
                  label={isSingleCheckbox ? 'Option' : 'Options'}
                  id="options"
                  error={optionsErr}
                  description={
                    isSingleCheckbox
                      ? 'This is the option for the checkbox'
                      : 'Type each option onto a new line'
                  }
                  dataQa="create-input-options"
                  value={optionsVal}
                  onChangeText={(str) =>
                    handleOptionsChange(str, 'props.options')
                  }
                />
              )}
              {!isHyperlink && (
                <Checkbox
                  id="make-field-optional"
                  value="1"
                  inline
                  name="make-field-optional"
                  label="Make this field optional"
                  dataQa="create-chk-optional"
                  checked={!field.validation.required}
                  onChange={() => {
                    handleChange(
                      !field.validation.required,
                      'validation.required',
                    );
                  }}
                />
              )}
            </Stack>
            <ButtonRow>
              <Button
                onClick={closeModal}
                variant="secondary"
                dataQa="create-btn-cancel"
              >
                Cancel
              </Button>

              {hasMoreThenOneStage ? (
                <Button
                  onClick={() => setStep(1)}
                  dataQa="create-btn-permissions"
                  iconRight={<IconArrowRight responsive />}
                  disabled={!step1Valid}
                >
                  Set permissions
                </Button>
              ) : (
                <Button
                  disabled={!step1Valid}
                  onClick={() => {}}
                  type="submit"
                  dataQa="create-btn-create"
                >
                  {actionType} field
                </Button>
              )}
            </ButtonRow>
          </>
        )}

        {step === 1 && (
          <>
            <h2>Privacy Options</h2>
            <Stack stackMultiplier={1}>
              <Stack stackMultiplier={0.5}>
                <Label htmlFor="learner">Who can complete this field?</Label>
                <Stack stackMultiplier={1}>
                  <ModalText>
                    Only the selected user will be able to complete this field.
                  </ModalText>
                  <RadioWrapper className="column">
                    {hasLearnerStage && (
                      <Radio
                        key="learner"
                        id="learner"
                        label="Learners"
                        dataQa="setModal-radio-learner"
                        inline
                        checked={field.completedBy === 'learner'}
                        onChange={() => {
                          handleChange('learner', 'completedBy');
                        }}
                      />
                    )}
                    {hasEmployerStage && (
                      <Radio
                        key="employer"
                        id="employer"
                        inline
                        label="Employers"
                        dataQa="setModal-radio-employer"
                        checked={field.completedBy === 'employer'}
                        onChange={() => {
                          handleChange('employer', 'completedBy');
                        }}
                      />
                    )}
                    {hasProviderStage && (
                      <Radio
                        key="trainingProvider"
                        id="trainingProvider"
                        inline
                        label="Training provider"
                        dataQa="setModal-radio-provider"
                        checked={field.completedBy === 'trainingProvider'}
                        onChange={() => {
                          handleChange('trainingProvider', 'completedBy');
                        }}
                      />
                    )}
                  </RadioWrapper>
                </Stack>
              </Stack>
              <Stack stackMultiplier={0.5}>
                <Label htmlFor="hide-sensitive-info">Hide this field?</Label>
                <Stack stackMultiplier={1}>
                  <ModalText>
                    Only the selected user type will be able to see{' '}
                    {!isHyperlink && ' and complete '}
                    this field.
                  </ModalText>
                  <Checkbox
                    id="hide-sensitive-info"
                    value="1"
                    inline
                    name="hide-sensitive-info"
                    label="Hide"
                    dataQa="privacy-chk-hide"
                    checked={field.isSensitive}
                    onChange={() => {
                      handleChange(!field.isSensitive, 'isSensitive');
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <ButtonRow>
              <Button
                onClick={() => setStep(0)}
                variant="secondary"
                dataQa="privacy-btn-back"
              >
                Back
              </Button>
              <Button
                onClick={() => {}}
                type="submit"
                dataQa="privacy-btn-submit"
              >
                Set permissions
              </Button>
            </ButtonRow>
          </>
        )}
      </Stack>
    </form>
  );
};

export default AddFormField;
