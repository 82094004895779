import { TIconType } from '../../components/icons/Icon';
import { IssueStatus } from '../enums/global-enums';
import {
  TFormInputType,
  TFormItemCompletedBy,
  TFormTextType,
  TFormTextVisibleTo,
  TFormImageType,
  TFormItemType,
  TPrePopFormInputType,
} from './form-types';

export interface IFieldValidation {
  minLength?: number;
  required?: boolean;
  email?: boolean;
  nationalInsurance?: boolean;
  ulnNumber?: boolean;
  postCode?: boolean;
  firstName?: boolean;
  lastName?: boolean;
  mobileNumber?: boolean;
  homeNumber?: boolean;
  building?: boolean;
  street?: boolean;
  county?: boolean;
  city?: boolean;
  options?: boolean;
  paragraph?: boolean;
  maxLength?: number;
  title?: boolean;
  description?: boolean;
  formName?: boolean;
  isEmailInUse?: boolean;
  sex?: boolean;
  sexCode?: boolean;
  lldd?: boolean;
  llddCode?: boolean;
  ethnicity?: boolean;
  hyperlink?: boolean;
}

export interface IFormText {
  id: string;
  itemType: 'text';
  inputType: TFormTextType; // TODO replace this with `type`, needs to be on back-end as well
  alwaysVisibleTo?: TFormTextVisibleTo;
  props: {
    text: string;
  };
  default?: boolean;
}

export interface IFormImage {
  id: string;
  itemType: 'image';
  inputType: TFormImageType;
  alwaysVisibleTo: 'both';
  props: {
    fileId: string;
  };
  default?: boolean;
}

export interface Iissue {
  text: string;
  status: IssueStatus;
  hasBeenModified: boolean;
}

export interface IFormField {
  id: string;
  itemType: TFormItemType;
  inputType: TFormInputType;
  isSensitive?: boolean;
  completedBy?: TFormItemCompletedBy;
  props: {
    value?: string;
    newValue?: string;
    oldValue?: string;
    description?: string;
    label: string;
    options?: string[];
    originalLabel?: string;
  };
  validation: IFieldValidation;
  issue?: Iissue;
  default?: boolean;
}

export interface IFormFieldPrePop {
  id: string;
  itemType: TFormItemType;
  inputType: TPrePopFormInputType;
  isSensitive?: boolean;
  completedBy?: string;
  props: {
    value?: string;
    newValue?: string;
    oldValue?: string;
    description?: string;
    label: string;
    options?: string[];
    originalLabel?: string;
  };
  validation: IFieldValidation;
  issue?: Iissue;
  default?: boolean;
}

export type CellType = 'TextInput' | 'Paragraph';

export type DataTableFormatType = 'Column Only' | 'Row Only' | 'Column and Row';

export interface ITableDataValue {
  value: string;
  type: CellType;
}

export interface IFormTable {
  alwaysVisibleTo: string;
  completedBy: TFormItemCompletedBy;
  id: string;
  itemType: 'text' | 'input';
  inputType: 'DataTable';
  isSensitive?: boolean;
  props: {
    description?: string;
    label: string;
    dataTableType: DataTableFormatType;
    columnCount: number;
    rowCount: number;
    rowHeaders: string[];
    columnHeaders: string[];
    data: ITableDataValue[][];
    isOptional?: boolean;
  };
  default?: boolean;
}

type TFormItemSpec = {
  label: string;
  icon: TIconType;
  isDisabled?: boolean;
  hasOptions?: boolean;
  enquiryDefault?: boolean;
};

export type TFormInsertSpec = {
  itemType: TFormImageType;
  type: TFormInputType;
} & TFormItemSpec;

export type TFormFieldSpec = {
  itemType: 'input';
  type: TFormInputType;
} & TFormItemSpec;

export type TFormPrePopFieldSpec = {
  itemType: 'prepop';
  type: TPrePopFormInputType;
} & TFormItemSpec;

export type TFormTextSpec = {
  itemType: 'text';
  type: TFormTextType;
} & TFormItemSpec;

export type TFormDataTableSpec = {
  itemType: 'text';
  type: 'DataTable';
} & TFormItemSpec;

export type TBlankInputs = (
  | TFormFieldSpec
  | TFormTextSpec
  | TFormInsertSpec
  | TFormDataTableSpec
  | TFormPrePopFieldSpec
)[];

export const blankImageInserts: TBlankInputs = [
  {
    itemType: 'Header',
    type: 'FileUpload',
    label: 'Company logo',
    icon: 'IconImage',
  },
  {
    itemType: 'Footer',
    type: 'FileUpload',
    label: 'Other logos',
    icon: 'IconImage',
  },
];

export const blankInputs: TBlankInputs = [
  {
    itemType: 'input',
    type: 'TextInput',
    label: 'Single-line text',
    icon: 'IconInputSingleText',
  },
  {
    itemType: 'input',
    type: 'Textarea',
    label: 'Multi-line text',
    icon: 'IconInputMultiText',
  },
  {
    itemType: 'input',
    type: 'Number',
    label: 'Number',
    icon: 'IconInputNumber',
  },
  {
    itemType: 'input',
    type: 'DateInput',
    label: 'Date',
    icon: 'IconCalendar',
  },
  {
    itemType: 'input',
    type: 'Checkbox',
    label: 'Single checkbox',
    icon: 'IconInputCheckbox',
    hasOptions: true,
  },
  {
    itemType: 'input',
    type: 'CheckboxList',
    label: 'Multiple checkbox',
    icon: 'IconInputCheckboxX2',
    hasOptions: true,
  },
  {
    itemType: 'input',
    type: 'RadioList',
    label: 'Radio button',
    icon: 'IconInputRadio',
    hasOptions: true,
  },
  {
    itemType: 'input',
    type: 'Select',
    label: 'Dropdown',
    icon: 'IconInputSelect',
    hasOptions: true,
  },
  {
    itemType: 'input',
    type: 'FileUpload',
    label: 'File upload',
    icon: 'IconFileGeneric',
  },
  {
    itemType: 'input',
    type: 'Signature',
    label: 'Signature',
    icon: 'IconSignature',
  },
  {
    itemType: 'text',
    type: 'Title',
    label: 'Title',
    icon: 'IconInputTitle',
    isDisabled: false,
  },
  {
    itemType: 'text',
    type: 'Paragraph',
    label: 'Paragraph',
    icon: 'IconParagraph',
  },
  {
    itemType: 'text',
    type: 'DataTable',
    label: 'Data table',
    icon: 'IconDataTableEmpty',
  },
  {
    itemType: 'input',
    type: 'Hyperlink',
    label: 'Hyperlink',
    icon: 'IconLink',
    isDisabled: false,
  },
];

export const blankPrePopLearnerInputs: TBlankInputs = [
  {
    itemType: 'prepop',
    type: 'FirstName',
    label: 'First Name',
    icon: 'IconLightning',
    enquiryDefault: true,
  },
  {
    itemType: 'prepop',
    type: 'Surname',
    label: 'Surname',
    icon: 'IconLightning',
    enquiryDefault: true,
  },
  {
    itemType: 'prepop',
    type: 'Building',
    label: 'Address Line 1',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'Street',
    label: 'Address Line 2',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'TownCity',
    label: 'Town or city',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'County',
    label: 'County',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'PostCode',
    label: 'Postcode',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'EmailAddress',
    label: 'Email address',
    icon: 'IconLightning',
    enquiryDefault: true,
  },
  {
    itemType: 'prepop',
    type: 'DOB',
    label: 'Date of birth',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'ULN',
    label: 'ULN',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'NINumber',
    label: 'NI Number',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'HomePhoneNumber',
    label: 'Home contact number',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'MobilePhoneNumber',
    label: 'Mobile number',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'Sex',
    label: 'Sex',
    icon: 'IconLightning',
    hasOptions: true,
  },
  {
    itemType: 'prepop',
    type: 'Ethnicity',
    label: 'Ethnicity',
    icon: 'IconLightning',
    hasOptions: true,
  },
  {
    itemType: 'prepop',
    type: 'LLDD',
    label: 'LLDD',
    icon: 'IconLightning',
    hasOptions: true,
  },
];

export const blankPrePopEmployerInputs: TBlankInputs = [
  {
    itemType: 'prepop',
    type: 'EmployerName',
    label: 'Employer Name',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'Ern',
    label: 'ERN',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'KeyContactName',
    label: 'Key Contact name',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'KeyJobRole',
    label: 'Key Job role/position',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'KeyEmailAddress',
    label: 'Key Email address',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'Building',
    label: 'Building',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'Street',
    label: 'Street',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'TownCity',
    label: 'Town or city',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'County',
    label: 'County',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'PostCode',
    label: 'Postcode',
    icon: 'IconLightning',
  },
  {
    itemType: 'prepop',
    type: 'PhoneNumber',
    label: 'Phone number',
    icon: 'IconLightning',
  },
];

export const getFieldTitle = (
  inputType: TFormInputType | TFormTextType | TPrePopFormInputType,
) => {
  return blankInputs.find((field) => field.type === inputType)?.label;
};

export const getPrepopLearnerFieldTitle = (
  inputType: TFormInputType | TFormTextType | TPrePopFormInputType,
) => {
  return blankPrePopLearnerInputs.find((field) => field.type === inputType)
    ?.label;
};

export const getPrepopEmployerFieldTitle = (
  inputType: TFormInputType | TFormTextType | TPrePopFormInputType,
) => {
  return blankPrePopEmployerInputs.find((field) => field.type === inputType)
    ?.label;
};
