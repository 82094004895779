import React, { SyntheticEvent, useState, useEffect } from 'react';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import {
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { MaxLength } from '../../../context-providers/enums/global-enums';
import { DialogHeader } from '../../layout/Layout.style';
import IconButton from '../../atoms/icon-button/IconButton';
import { IconClose } from '../../icons';
import { navigate } from 'gatsby';

const EditInitialFormName = () => {
  const closeModal = useCloseModal();
  const newForm = useNewForm();
  const [formName, setFormName] = useState(newForm.formName);
  const updateForm = useSetNewForm();
  const nameError = validateField(formName, { required: true, formName: true });
  const isValid = !nameError;

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    updateForm({ ...newForm, formName });
    closeModal();
  };

  const cancel = () => {
    closeModal();
    navigate(`/list-forms`);
  };

  useEffect(() => {
    const handlePopState = () => {
      cancel();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (formName !== '') {
      closeModal();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={1.5}>
        <DialogHeader>
          <h2>New form name</h2>
          <IconButton
            variant="black"
            onClick={() => cancel()}
            icon={<IconClose responsive />}
          ></IconButton>
        </DialogHeader>
        <TextInput
          maxLength={MaxLength.FormName}
          value={formName}
          id="formName"
          dataQa="edit-input-name"
          error={nameError}
          placeholder="Enter a Form Name"
          onChangeText={(str) => setFormName(str)}
        />
        <ButtonRow>
          <Button type="submit" disabled={!isValid} dataQa="edit-btn-save">
            Save
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditInitialFormName;
