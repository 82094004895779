import styled from 'styled-components';
import { boxShadow, color } from '../../config';

export const MismatchWarningBox = styled.div<{ large?: boolean }>`
  color: ${color.status.warning};
  p {
    padding-left: 3rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }
  outline: solid;
  outline-color: ${color.status.warning};
  outline-width: 1px;
  box-shadow: ${boxShadow.error};
  padding: 0.75em 1em;
  border-radius: 0.5em;
  background-color: ${color.status.warningBg};
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;

  svg {
    justify-content: flex-start;
    width: ${(props) => (props.large ? 80 : 24)}px;
    height: ${(props) => (props.large ? 80 : 24)}px;
    resize: 'cover';
  }
`;
