import React, { useEffect } from 'react';
import ReactGA from '../../../utils/google-analytics';
import {
  useClearNewForm,
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import { IconAssessments, IconCheck, IconShow, IconWarning } from '../../icons';
import {
  BuildFormHeader,
  BuildFormContent,
  BuildFormSidebar,
} from './BuildForm.style';
import BuildFormInner from './BuildFormInner';
import CreateFormField from './CreateFormField';
import { navigate } from 'gatsby';
import { IFormTemplate } from '../../../context-providers/forms/forms-reducer';
import SaveDraft from '../../modals/forms/SaveDraft';
import Publish from '../../modals/forms/Publish';
import { MismatchWarningBox } from '../../modals/forms/ReassignForm.style';

const isBrowser = typeof window !== 'undefined';

const BuildFormContainer = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const formTemplate = useNewForm();
  const setForm = useSetNewForm();
  const showModal = useShowModal();
  const clearNewForm = useClearNewForm();
  const isValid = !!formTemplate.formData.length;

  const viewForm = (form: IFormTemplate) => {
    setForm(formTemplate);
    navigate(`/view-draft-form/?ftid=${form.id}`);
  };

  useEffect(() => {
    return clearNewForm;
  }, []);

  return (
    <>
      <BuildFormHeader>
        <h2>Create form template</h2>
        <Button
          disabled={!isValid || formTemplate.status === 'published'}
          variant="secondary"
          dataQa="form-btn-save"
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Build form - save draft',
            });
            showModal(SaveDraft);
          }}
          iconRight={<IconAssessments responsive />}
        >
          Save draft
        </Button>
        <Button
          disabled={
            !isValid || !formTemplate.id || formTemplate.status === 'published'
          }
          variant="secondary"
          dataQa="form-btn-preview"
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Build form - preview draft',
            });
            viewForm(formTemplate);
          }}
          iconRight={<IconShow responsive />}
        >
          Preview last save
        </Button>
        <Button
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Build form - publish',
            });
            showModal(Publish);
          }}
          iconRight={<IconCheck responsive />}
          dataQa="form-btn-publish"
          disabled={!isValid || formTemplate.status === 'published'}
        >
          Publish
        </Button>
      </BuildFormHeader>
      <GridFlex gutters={false} align="stretch">
        <BuildFormSidebar small={4} gutters={false}>
          <CreateFormField></CreateFormField>
        </BuildFormSidebar>
        <BuildFormContent small={8} gutters={false}>
          {formTemplate.workflowStagesMismatch && (
            <MismatchWarningBox>
              <IconWarning></IconWarning>
              <p>
                The recipients of the assigned workflow do not match the
                original workflow, you may need to add or remove fields from the
                form.
              </p>
            </MismatchWarningBox>
          )}
          <BuildFormInner />
        </BuildFormContent>
      </GridFlex>
    </>
  );
};

export default BuildFormContainer;
