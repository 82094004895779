import React, { useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import Stack from '../../atoms/stack/Stack';
import { FormFieldButtons } from './CreateFormField.style';
import {
  blankPrePopLearnerInputs,
  blankPrePopEmployerInputs,
  TFormPrePopFieldSpec,
  TFormFieldSpec,
  TFormTextSpec,
  TFormInsertSpec,
  TFormDataTableSpec,
  IFormFieldPrePop,
} from '../../../context-providers/forms/forms-fields';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import Icon from '../../icons/Icon';
import { useNewForm } from '../../../context-providers/forms/forms-hooks';
import { validateMaxFields } from '../../../context-providers/forms/forms-validation';
import { ButtonToggle } from './FieldsPrePopulated.style';
import { IconChevronCollapse, IconChevronExpand } from '../../icons';
import { getQueryVar } from '../../../utils/query-vars';
import FormWorkflowUtil from '../../../utils/form-workflow-util';
import { StageId } from '../../../context-providers/forms/form-workflows';
import {
  ILearnerEthnicity,
  ILearnerLLDD,
  ILearnerSex,
} from '../../../context-providers/learner/learner-reducer';
import ReferenceDataHelper from '../../../utils/reference-data-helper';
import AddFormFieldPrePop from '../../modals/forms/AddFormFieldPrePop';

const FieldsPrePopulated = () => {
  const showModal = useShowModal();
  const newForm = useNewForm();
  const hasMaxFields = !validateMaxFields(newForm);
  const workflowId = getQueryVar('workflowId');

  const [showLearnerFields, setShowLearnerFields] = useState<boolean>(false);
  const [showEmployerFields, setShowEmployerFields] = useState<boolean>(false);

  const [showEmployerSection, setShowEmployerSection] =
    useState<boolean>(false);
  const [showLearnerSection, setShowLearnerSection] = useState<boolean>(false);

  const getWorkflow = () => {
    if (!workflowId) return;

    FormWorkflowUtil.getWorkflow(workflowId).then((flow) => {
      setShowEmployerSection(
        flow.stages.findIndex((x) => x.stageId === StageId.EMPLOYER) > -1,
      );
      setShowLearnerSection(
        flow.stages.findIndex(
          (x) => x.stageId === StageId.LEARNER || x.stageId === StageId.ENQUIRY,
        ) > -1,
      );
    });
  };

  useEffect(() => {
    getWorkflow();
  }, []);

  const showLearnerFieldsClick = () => {
    setShowLearnerFields(!showLearnerFields);
  };

  const showEmployerFieldsClick = () => {
    setShowEmployerFields(!showEmployerFields);
  };

  const [lldds, setLldds] = useState<ILearnerLLDD[]>([]);
  const [llddsLoaded, setLlddsLoaded] = useState<boolean>(false);

  const [ethnicities, setEthnicities] = useState<ILearnerEthnicity[]>([]);
  const [ethnicitiesLoaded, setEthnicitiesLoaded] = useState<boolean>(false);

  const [sex, setSex] = useState<ILearnerSex[]>([]);
  const [sexLoaded, setSexLoaded] = useState<boolean>(false);

  const loadLLDDs = (): void => {
    if (!llddsLoaded) {
      ReferenceDataHelper.getLllds().then((response) => {
        setLldds(response);
        setLlddsLoaded(true);
      });
    }
  };

  const llddOptionsArray = lldds.map((d) => d.definition) || [];

  const loadEthnicities = (): void => {
    if (!ethnicitiesLoaded) {
      ReferenceDataHelper.getEthnicities().then((response) => {
        setEthnicities(response);
        setEthnicitiesLoaded(true);
      });
    }
  };

  const ethnicityOptionsArray = ethnicities.map((d) => d.definition) || [];

  const loadSex = (): void => {
    if (!sexLoaded) {
      ReferenceDataHelper.getSex().then((response) => {
        setSex(response);
        setSexLoaded(true);
      });
    }
  };

  const sexOptionsArray = sex.map((d) => d.definition) || [];

  const GetOptions = (
    item:
      | TFormFieldSpec
      | TFormTextSpec
      | TFormInsertSpec
      | TFormDataTableSpec
      | TFormPrePopFieldSpec,
  ) => {
    const optionsArray =
      item.type === 'Sex'
        ? sexOptionsArray
        : item.type === 'Ethnicity'
        ? ethnicityOptionsArray
        : item.type === 'LLDD'
        ? llddOptionsArray
        : [];
    return optionsArray;
  };

  useEffect(() => {
    loadSex();
  }, [sexLoaded]);

  useEffect(() => {
    loadEthnicities();
  }, [ethnicitiesLoaded]);

  useEffect(() => {
    loadLLDDs();
  }, [llddsLoaded]);

  const handlePrePopFieldClick = (
    item:
      | TFormFieldSpec
      | TFormTextSpec
      | TFormInsertSpec
      | TFormDataTableSpec
      | TFormPrePopFieldSpec,
    formCompletedBy: string,
  ) => {
    showModal(AddFormFieldPrePop, {
      formFieldSpec: item,
      formCompletedBy,
      hasLearnerStage: showLearnerSection,
      hasEmployerStage: showEmployerSection,
      options: GetOptions(item),
    });
  };

  const isPrePopFieldInUse = (type: string, completedBy: string): boolean => {
    return !!newForm.formData.find(
      (formElement) =>
        formElement.inputType === type &&
        (formElement as IFormFieldPrePop).completedBy === completedBy,
    );
  };

  return (
    <Stack stackMultiplier={2}>
      <p>
        Use these fields if you'd like to save the data to the form recipient's
        record and pre-populate each form.
      </p>

      {showLearnerSection && (
        <ButtonToggle>
          <button
            type="button"
            onClick={() => {
              showLearnerFieldsClick();
            }}
          >
            <span>Learner data fields</span>
            {showLearnerFields && <IconChevronCollapse />}
            {!showLearnerFields && <IconChevronExpand />}
          </button>
        </ButtonToggle>
      )}

      {showLearnerSection && showLearnerFields && (
        <FormFieldButtons>
          {blankPrePopLearnerInputs.map((item, i) => {
            item.isDisabled = isPrePopFieldInUse(item.type, 'learner');
            return (
              <button
                type="button"
                data-qa={`form-btn-${item.type}`}
                key={`${item.type}-${i}`}
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: `Add pre-populated field - ${item.label}`,
                  });
                  handlePrePopFieldClick(item, 'learner');
                }}
                disabled={item.isDisabled || hasMaxFields}
              >
                <Icon type={item.icon} />
                <span>{item.label}</span>
              </button>
            );
          })}
        </FormFieldButtons>
      )}

      {showEmployerSection && (
        <ButtonToggle>
          <button
            type="button"
            onClick={() => {
              showEmployerFieldsClick();
            }}
          >
            <span>Employer data fields</span>
            {showEmployerFields && <IconChevronCollapse />}
            {!showEmployerFields && <IconChevronExpand />}
          </button>
        </ButtonToggle>
      )}

      {showEmployerSection && showEmployerFields && (
        <FormFieldButtons>
          {blankPrePopEmployerInputs.map((item, i) => {
            item.isDisabled = isPrePopFieldInUse(item.type, 'employer');
            return (
              <button
                type="button"
                data-qa={`form-btn-${item.type}`}
                key={`${item.type}-${i}`}
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: `Add pre-populated field - ${item.label}`,
                  });
                  handlePrePopFieldClick(item, 'employer');
                }}
                disabled={item.isDisabled || hasMaxFields}
              >
                <Icon type={item.icon} />
                <span>{item.label}</span>
              </button>
            );
          })}
        </FormFieldButtons>
      )}
    </Stack>
  );
};

export default FieldsPrePopulated;
