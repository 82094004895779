import styled from 'styled-components';
import { Link } from 'gatsby';
import { color } from '../../config';

export const TabLinks = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  a {
    font-size: 1.6rem;
    margin-right: 4rem;
    display: inline-block;
    min-width: 3rem;
    text-align: center;
  }
`;

export const TabLink = styled(Link)`
  display: block;
  border-bottom: 4px solid transparent;
  padding: 1.2rem 0;
  text-decoration: none;
  color: ${color.grey.dark};
  font-weight: 400;

  &[aria-selected='true'] {
    border-color: ${color.secondary.dark};
    color: ${color.secondary.dark};
    font-weight: 700;
  }

  svg {
    display: inline-block;
    width: 2rem;
    margin: auto;
    vertical-align: middle;
  }
`;

export const Actions = styled.div`
  margin-left: auto;
`;
