import React, { SyntheticEvent, useEffect, useState } from 'react';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import {
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { MaxLength } from '../../../context-providers/enums/global-enums';

const EditFormName = () => {
  const closeModal = useCloseModal();
  const newForm = useNewForm();
  const [formName, setFormName] = useState(newForm.formName);
  const updateForm = useSetNewForm();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    updateForm({ ...newForm, formName });
    closeModal();
  };

  const nameError = validateField(formName, { required: true, formName: true });
  const isValid = !nameError;

  useEffect(() => {
    const handlePopState = () => {
      closeModal();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <h2>Edit form name</h2>
        <TextInput
          maxLength={MaxLength.FormName}
          label="Form name"
          value={formName}
          id="formName"
          dataQa="edit-input-name"
          error={nameError}
          placeholder="Form Name"
          onChangeText={(str) => setFormName(str)}
        />
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="edit-btn-cancel"
            disabled={!isValid}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={!isValid} dataQa="edit-btn-save">
            Save
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditFormName;
