import styled from 'styled-components';
import { color } from '../../config';

export const ButtonToggle = styled.div`
  button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${color.grey.light};
    border: none;
    padding: 1.3rem 1.6rem;
    font-weight: 600;
    font-size: 1.6rem;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    text-align: justify;

    span {
      display: inline-block;
      text-align: justify;
    }

    svg {
      margin-left: 8rem;
      justify-content: right;
    }
  }
`;
