import styled from 'styled-components';
import { color } from '../../config';

interface IFormFieldsProps {
  isDataTable: boolean;
}

export const FormFields = styled.div<IFormFieldsProps>`
  max-width: ${(props) => (props.isDataTable ? '100%' : '38.2rem')};
  margin: auto;
  width: ${(props) => (props.isDataTable ? '100%' : '60%')};
`;

export const BuildFormField = styled.div<{
  isTextField?: boolean;
  isFooter?: boolean;
}>`
  position: relative;
  border: 1px dashed ${color.grey.light};
  border-radius: 6px;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  display: flex;
  align-items: center;
  min-height: 5rem;
  padding: ${(props) =>
    props.isTextField ? '6rem 0.5rem 2rem' : '6rem 2rem 2rem'};

  @supports selector(:focus-within) {
    padding: ${(props) => (props.isTextField ? '0.5rem' : '2rem')};
  }

  &:hover {
    border-color: ${color.primary.base};
    background-color: ${color.secondary.base};
  }

  &:focus-within {
    border-color: ${color.primary.base};
    background-color: ${color.secondary.base};
  }
`;

export const FormFieldActions = styled.div`
  top: 2px;
  right: 2px;
  height: 44px;
  position: absolute;
  z-index: 10;
  background-color: ${color.light};
  border-radius: 6px;
  overflow: hidden;

  display: flex;
  align-content: center;
  opacity: 1;
  transition: opacity 0.1s ease;

  // IE 11 fallback - always show
  @supports selector(:focus-within) {
    opacity: 0;
  }

  ${BuildFormField}:hover & {
    opacity: 1;
  }

  ${BuildFormField}:focus-within & {
    opacity: 1;
  }
`;

export const FormFieldButton = styled.button`
  background-color: ${color.primary.base};
  color: ${color.light};
  font-size: 1.6rem;
  border: 0;
  width: 5rem;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.2s ease;
  border-right: 1px solid ${color.primary.light};
  line-height: 1;

  svg {
    vertical-align: middle;
  }

  &:last-of-type {
    border: 0;
  }

  &:hover:not(:disabled),
  &:focus,
  &:focus-visible {
    background-color: ${color.primary.dark};
  }

  &:focus,
  &:focus-visible {
    outline: 2px dashed white;
    outline-offset: -4px;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${color.primary.dark};
  }
`;
