import React from 'react';
import { WordWrapBox } from '../../../layout/Layout.style';
import Label from '../label/Label';
import {
  InputRadio,
  InputRadioField,
  InputRadioProps,
  RadioWithIconWrapper,
} from './Radio.style';

export interface IRadio extends InputRadioProps {
  checked?: boolean;
  id: string;
  label?: string;
  disabled?: boolean;
  name?: string;
  readOnly?: boolean;
  value?: string;
  inline?: boolean;
  isWrapped?: boolean;
  children?: React.ReactNode;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  dataQa?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

const RadioWithIcon = (props: IRadio) => {
  const {
    disabled = false,
    id,
    label,
    readOnly = false,
    iconLeft,
    iconRight,
    dataQa,
  } = props;
  const { children, ...otherProps } = props;
  return (
    <RadioWithIconWrapper>
      <InputRadioField>
        <InputRadio
          type="radio"
          data-qa={dataQa}
          {...otherProps}
          isWrapped={false}
          disabled={disabled || readOnly}
        />
      </InputRadioField>
      {iconLeft}
      <WordWrapBox>
        <Label htmlFor={id} dataQa={`form-radio-${label?.split(' ')[0]}`}>
          {label}
        </Label>
      </WordWrapBox>
      {iconRight}
    </RadioWithIconWrapper>
  );
};

export default RadioWithIcon;
