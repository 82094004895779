import { navigate } from 'gatsby';
import React, { SyntheticEvent, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import {
  useClearNewForm,
  useEditForm,
} from '../../../context-providers/forms/forms-hooks';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import buildFormAutoSaveSubject from '../../../context-providers/build-form-autosave/BuildFormAutoSaveSubject';

const SaveDraft = () => {
  const closeModal = useCloseModal();
  const clearNewForm = useClearNewForm();
  const saveForm = useEditForm();
  const [isComplete, setIsComplete] = useState(false);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    buildFormAutoSaveSubject.setFormSaving();
    const res = await saveForm('saved');
    if (res.success) {
      setIsComplete(true);
      buildFormAutoSaveSubject.setFormSavedSuccess();
      buildFormAutoSaveSubject.timer.resetTimer();
    } else {
      buildFormAutoSaveSubject.setFormSavedFailed();
    }
  };

  const description = (
    <p>
      You can return to your drafts from the 'Form templates' page whenever you
      like.
    </p>
  );

  return (
    <form
      onSubmit={(event) => {
        ReactGA.event({
          category: 'Button',
          action: 'Save draft modal - Save draft',
        });
        handleSubmit(event);
      }}
    >
      <Stack stackMultiplier={2}>
        {isComplete ? (
          <>
            <Stack stackMultiplier={0.5}>
              <h2>Form saved as a draft</h2>
              {description}
            </Stack>
            <ButtonRow>
              <Button
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Save draft modal - View drafts',
                  });
                  clearNewForm();
                  closeModal();
                  navigate('/list-forms/');
                }}
                variant="secondary"
                dataQa="saveDraft-btn-view"
              >
                View drafts
              </Button>
              <Button
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Save draft modal - Continue editing',
                  });
                  closeModal();
                }}
                dataQa="saveDraft-btn-continue"
              >
                Continue editing
              </Button>
            </ButtonRow>
          </>
        ) : (
          <>
            <Stack stackMultiplier={0.5}>
              <h2>Save as draft</h2>
              {description}
            </Stack>
            <ButtonRow>
              <Button
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Save draft modal - Cancel',
                  });
                  closeModal();
                }}
                variant="subtle"
                dataQa="saveDraft-btn-cancel"
              >
                Cancel
              </Button>
              <Button type="submit" dataQa="saveDraft-btn-submit">
                Save draft
              </Button>
            </ButtonRow>
          </>
        )}
      </Stack>
    </form>
  );
};

export default SaveDraft;
