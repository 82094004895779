import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DataTableFormatType,
  IFormTable,
} from '../../../context-providers/forms/forms-fields';
import {
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import {
  useCloseModal,
  useUpdateModalStyle,
} from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import { Flex } from '../../atoms/custom-elements/Flex.style';
import {
  IconDataTableColumnRows,
  IconDataTableColumnsOnly,
  IconDataTableRowsOnly,
} from '../../icons';
import Stack from '../../atoms/stack/Stack';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import { ModalText, RadioWrapper } from '../ModalContent.style';
import RadioWithIcon from '../../atoms/form-fields/radio/RadioWithIcon';
import Radio from '../../atoms/form-fields/radio/Radio';
import { DataTableMapper } from './mappers/IDataTableMapper';
import CreateTextFromTable from './CreateTextFromTable';
import Checkbox from '../../atoms/form-fields/checkbox/Checkbox';
import Label from '../../atoms/form-fields/label/Label';
import { StageId } from '../../../context-providers/forms/form-workflows';
import { IWorkflow } from '../../../context-providers/forms/forms-reducer';
import {
  TFormItemCompletedBy,
  TFormTextVisibleTo,
} from '../../../context-providers/forms/form-types';
import RichTextInput from '../../atoms/form-fields/rich-text-input/RichTextInput';

const createBlankFormTable = (): IFormTable => {
  return {
    id: uuidv4(),
    itemType: 'text',
    inputType: 'DataTable',
    alwaysVisibleTo: 'both',
    completedBy: 'learner',
    isSensitive: false,
    props: {
      description: '',
      label: '',
      dataTableType: 'Column Only',
      rowCount: 0,
      columnCount: 0,
      columnHeaders: [],
      rowHeaders: [],
      data: [],
      isOptional: true,
    },
  };
};

export interface ICreateDataTable {
  currentTable?: IFormTable;
  index?: number;
  workflow: IWorkflow;
}

const CreateDataTable = ({
  currentTable,
  index,
  workflow,
}: ICreateDataTable) => {
  const closeModal = useCloseModal();
  const onUpdateModalStyle = useUpdateModalStyle();
  const setNewForm = useSetNewForm();

  const [step, setStep] = useState(0);
  const [step3Valid, setStep3Valid] = useState(false);
  const [table, setTable] = useState<IFormTable>(
    currentTable || createBlankFormTable(),
  );
  const newForm = useNewForm();
  const [columnCount, setColumnCount] = useState(
    currentTable?.props.columnCount.toString() || '',
  );
  const [rowCount, setRowCount] = useState(
    currentTable?.props.rowCount.toString() || '',
  );

  const [tableFormat, setTableFormat] = useState<
    DataTableFormatType | 'Undefined'
  >(currentTable?.props.dataTableType ?? 'Undefined');

  const [hasLearnerStage, setHasLearnerStage] = useState<boolean>(false);
  const [hasEmployerStage, setHasEmployerStage] = useState<boolean>(false);
  const [hasProviderStage, setHasProviderStage] = useState<boolean>(false);
  const [hasMoreThenOneStage, setHasMoreThenOneStage] =
    useState<boolean>(false);

  const setPermissions = () => {
    const a = !!workflow.stages.find((x) => x.stageId === StageId.LEARNER);
    const b = !!workflow.stages.find((x) => x.stageId === StageId.EMPLOYER);
    const c = !!workflow.stages.find((x) => x.stageId === StageId.PROVIDER);
    setHasLearnerStage(a);
    setHasEmployerStage(b);
    setHasProviderStage(c);
    setHasMoreThenOneStage((a && b) || (a && c) || (b && c));
    if (!hasMoreThenOneStage) {
      let value: TFormTextVisibleTo = 'learner';
      if (hasEmployerStage) value = 'employer';
      if (hasProviderStage) value = 'trainingProvider';
      handleChange(value, 'visible');
    }
  };

  useEffect(() => {
    setPermissions();
  }, []);

  const handleChange = (value: string, type: string) => {
    if (type === 'column') {
      setColumnCount(value);
      if (!validateRange(value, 7)) {
        setTable(
          produce(table, (newTable) => {
            newTable.props.columnCount = Number(value);
            return newTable;
          }),
        );
      }
    } else if (type === 'row') {
      setRowCount(value);
      if (!validateRange(value, 100)) {
        setTable(
          produce(table, (newTable) => {
            newTable.props.rowCount = Number(value);
            return newTable;
          }),
        );
      }
    } else if (type === 'itemType' && (value === 'input' || value === 'text')) {
      setTable(
        produce(table, (newTable) => {
          newTable.props.isOptional = value === 'text';
          newTable.itemType = value;
          return newTable;
        }),
      );
    } else if (type === 'permissions') {
      setTable(
        produce(table, (newTable) => {
          newTable.completedBy = value as TFormItemCompletedBy;
          return newTable;
        }),
      );
    } else if (type === 'visible') {
      setTable(
        produce(table, (newTable) => {
          newTable.alwaysVisibleTo = value;
          return newTable;
        }),
      );
    }
  };

  const handleFormatChange = (value: DataTableFormatType) => {
    setTableFormat(value);
    if (!validateFormat(value)) {
      setTable(
        produce(table, (newTable) => {
          newTable.props.dataTableType = value;
          return newTable;
        }),
      );
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const form = produce(newForm, (d) => {
      if (table && index !== undefined) {
        d.formData[index] = table;
      } else {
        const footerIndex = d.formData.findIndex(
          (f) => f.inputType === 'Footer',
        );
        if (table) {
          if (footerIndex < 0) {
            d.formData.push(table);
          } else {
            d.formData.splice(footerIndex, 0, table);
          }
        }
      }
      return d;
    });
    setNewForm(form);
    closeModal();
  };

  const fillDataAndColumnHeaders = () => {
    setTable(
      produce(table, (newTable) => {
        return DataTableMapper.fillHeadersAndData(newTable);
      }),
    );
  };

  const handleBack = (colTable: IFormTable) => {
    setTable(colTable);
    if (Number(columnCount) !== colTable.props.columnCount) {
      setColumnCount(colTable.props.columnCount.toString());
    }
    if (Number(rowCount) !== colTable.props.rowCount) {
      setRowCount(colTable.props.rowCount.toString());
    }
    onUpdateModalStyle({
      content: {
        width: '806px',
        maxWidth: '806px',
      },
    });

    setStep(2);
  };

  const validateRange = (value: string, max: number) => {
    if (!value) {
      return 'Please enter a value';
    }

    const countNumber = Number(value);
    if (Number.isNaN(countNumber)) {
      return 'Please enter a numeric value';
    }

    if (!value.match(/^[\d]+$/)) {
      return 'Please enter a whole number';
    }

    if (countNumber < 1) {
      return 'The minimum value is 1';
    }

    if (countNumber > max) {
      return `The maximum value is ${max}`;
    }

    return '';
  };

  const validateFormat = (value: DataTableFormatType | 'Undefined') => {
    if (
      value === 'Column Only' ||
      value === 'Row Only' ||
      value === 'Column and Row'
    ) {
      return '';
    }

    return 'Please select a format for the table';
  };

  const validateTitle = () => {
    if (table.props.label.length === 0) {
      return 'Please enter a title';
    }
    if (table.props.label.length > 1000) {
      return 'Too long - maximum 1000 characters';
    }
    return '';
  };

  const titleError = validateTitle();

  const descriptionError =
    (table.props.description?.length ?? 0) > 20000
      ? 'Too long - maximum 20000 characters'
      : '';

  const formatError = validateFormat(tableFormat);

  const rowError = validateRange(rowCount, 100);
  const columnError = validateRange(columnCount, 7);
  const step0Valid = !titleError && !descriptionError;
  const step1Valid = !rowError && !columnError && !formatError;

  useEffect(() => {
    onUpdateModalStyle({
      content: {
        width: '806px',
        maxWidth: '806px',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTable]);

  return (
    <>
      {step === 0 && (
        <Stack stackMultiplier={2}>
          <h2>Add data table</h2>
          <RichTextInput
            id="tableTitle"
            label="Title"
            dataQa="datatable-modal-title-input"
            error={titleError}
            description="Give your data table a title, e.g. 'Monthly calculation'"
            value={table.props.label}
            onChangeText={(label) => {
              setTable(
                produce(table, (newTable) => {
                  newTable.props.label = label;
                  return newTable;
                }),
              );
            }}
          />
          <RichTextInput
            id="tableDescription"
            label="Description"
            dataQa="datatable-modal-description-input"
            description="Enter text that'll appear under the title (Optional)"
            value={table.props.description}
            error={descriptionError}
            as="html-description"
            onChangeText={(description) => {
              setTable(
                produce(table, (newTable) => {
                  newTable.props.description = description;
                  return newTable;
                }),
              );
            }}
          />
          <ButtonRow>
            <Button
              dataQa="datatable-modal-cancel-button"
              onClick={() => {
                closeModal();
              }}
              variant="subtle"
            >
              Cancel
            </Button>
            <Button
              dataQa="datatable-modal-continue-button"
              onClick={() => {
                setStep(1);
              }}
              disabled={!step0Valid}
            >
              Continue
            </Button>
          </ButtonRow>
        </Stack>
      )}

      {step === 1 && (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <h2>Add data table</h2>
            <h3>
              Will form recipients need to be able to input information into the
              table?
            </h3>
          </Stack>

          <Stack>
            <RadioWrapper className="column" style={{ marginTop: '-1.5rem' }}>
              <Radio
                key="rdbInputYes"
                id="rdbInputInfoYes"
                inline
                dataQa="datatable-modal-yes-radio"
                element={
                  // eslint-disable-next-line
                  <label
                    htmlFor="rdbInputInfoYes"
                    style={{ fontSize: '1.49rem' }}
                  >
                    <>
                      <b>Yes</b> - they'll need to be able to type in the cells
                    </>
                  </label>
                }
                checked={table.itemType === 'input'}
                onChange={() => {
                  handleChange('input', 'itemType');
                }}
              />

              <Radio
                key="rdbInputInfoNo"
                id="rdbInputInfoNo"
                inline
                dataQa="datatable-modal-no-radio"
                element={
                  // eslint-disable-next-line
                  <label
                    htmlFor="rdbInputInfoNo"
                    style={{ fontSize: '1.5rem' }}
                  >
                    <>
                      <b>No</b> - this is a display table
                    </>
                  </label>
                }
                checked={table.itemType === 'text'}
                onChange={() => {
                  handleChange('text', 'itemType');
                }}
              />
            </RadioWrapper>
            {table.itemType === 'input' && (
              <Checkbox
                id="make-field-optional"
                value="1"
                inline
                checked={table.props.isOptional}
                name="make-field-optional"
                label="Make this field optional"
                onChange={() => {
                  setTable(
                    produce(table, (newTable) => {
                      newTable.props.isOptional = !newTable.props.isOptional;
                      return newTable;
                    }),
                  );
                }}
              />
            )}
          </Stack>

          <ButtonRow>
            <Button
              dataQa="datatable-modal-back-button"
              onClick={() => {
                setStep(0);
              }}
              variant="subtle"
            >
              Back
            </Button>
            <Button
              dataQa="datatable-modal-continue-button"
              onClick={() => {
                setStep(2);
              }}
            >
              Continue
            </Button>
          </ButtonRow>
        </Stack>
      )}

      {step === 2 && (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <h2>Add data table</h2>
            <p>Customise the configuration of your table</p>
          </Stack>

          <Stack stackMultiplier={0.5}>
            <h3>Header cells</h3>
            <p>Select where you'd like to add header cells to your table</p>
            <RadioWrapper className="column">
              <RadioWithIcon
                key="columnandrow"
                id="columnandrow"
                label="Rows and Columns"
                dataQa="datatable-modal-radio-columnandrow"
                iconLeft={<IconDataTableColumnRows responsive />}
                inline
                isWrapped
                checked={tableFormat === 'Column and Row'}
                onChange={() => {
                  handleFormatChange('Column and Row');
                }}
              />
              <RadioWithIcon
                key="columnonly"
                id="columnonly"
                iconLeft={<IconDataTableColumnsOnly responsive />}
                inline
                isWrapped
                label="Columns Only"
                dataQa="datatable-modal-radio-columnonly"
                checked={tableFormat === 'Column Only'}
                onChange={() => {
                  handleFormatChange('Column Only');
                }}
              />

              <RadioWithIcon
                key="rowonly"
                id="rowonly"
                iconLeft={<IconDataTableRowsOnly responsive />}
                inline
                isWrapped
                label="Rows Only"
                dataQa="datatable-modal-radio-rowonly"
                checked={tableFormat === 'Row Only'}
                onChange={() => {
                  handleFormatChange('Row Only');
                }}
              />
            </RadioWrapper>
            <p>{formatError}</p>
          </Stack>
          <Stack>
            <TextInput
              id="tableColumnCount"
              dataQa="datatable-modal-columns-input"
              label="Number of columns"
              description={`Header cells aren’t included in the number you enter. \nColumns can still be added or removed later. \nMaximum of 7.`}
              value={columnCount}
              error={columnError}
              style={{ maxWidth: '12.5rem' }}
              onChangeText={(columns) => {
                handleChange(columns, 'column');
              }}
            />
          </Stack>
          <Stack>
            <TextInput
              id="tableRowCount"
              label="Number of rows"
              dataQa="datatable-modal-rows-input"
              description={`Header cells aren’t included in the number you enter. \nRows can still be added or removed later. \nMaximum of 100.`}
              value={rowCount}
              error={rowError}
              style={{ maxWidth: '12.5rem' }}
              onChangeText={(rows) => {
                handleChange(rows, 'row');
              }}
            />
          </Stack>
          <ButtonRow>
            <Button
              onClick={() => {
                setStep(1);
              }}
              variant="subtle"
              dataQa="datatable-modal-back-button"
            >
              Back
            </Button>
            <Button
              dataQa="datatable-modal-continue-button"
              onClick={() => {
                fillDataAndColumnHeaders();
                onUpdateModalStyle({
                  content: {
                    width: '100%',
                    maxWidth: '100%',
                  },
                });
                setStep(3);
              }}
              disabled={!step1Valid}
            >
              Continue
            </Button>
          </ButtonRow>
        </Stack>
      )}

      {step === 3 && (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <Flex alignItems="center">
              <IconDataTableColumnRows />
              <h1 style={{ marginLeft: 8 }}>Add data table</h1>
            </Flex>
            <p>Build and customise your data table</p>
          </Stack>
          <CreateTextFromTable
            dataTable={table}
            onChange={(a) => {
              setTable(a.dataTable);
              setStep3Valid(a.isValid);
            }}
          />
          <ButtonRow>
            <Button
              dataQa="datatable-modal-back-button"
              variant="subtle"
              onClick={() => {
                handleBack(table);
              }}
            >
              Back
            </Button>
            <Button
              dataQa="datatable-modal-continue-button"
              disabled={!step3Valid}
              onClick={() => {
                onUpdateModalStyle({
                  content: {
                    width: '806px',
                    maxWidth: '806px',
                  },
                });

                if (!hasEmployerStage && !hasProviderStage) {
                  handleSubmit();
                } else {
                  setStep(4);
                }
              }}
            >
              Continue
            </Button>
          </ButtonRow>
        </Stack>
      )}

      {step === 4 && (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <h2>Add data table</h2>
            <h3 style={{ marginTop: '2rem' }}>Set permissions</h3>
            {table.itemType === 'input' && (
              <p>Select who can view and complete this data table</p>
            )}
            {table.itemType === 'text' && (
              <p>Select who can view this data table</p>
            )}
          </Stack>

          <Stack>
            <RadioWrapper className="column" style={{ marginTop: '-1rem' }}>
              {table.itemType === 'input' && (
                <>
                  {hasLearnerStage && (
                    <Radio
                      key="rdbPermissionsLrn"
                      id="rdbPermissionsLrn"
                      inline
                      label="Learners"
                      dataQa="datatable-modal-permission-learner"
                      checked={table.completedBy === 'learner'}
                      onChange={() => {
                        handleChange('learner', 'permissions');
                      }}
                    />
                  )}
                  {hasEmployerStage && (
                    <Radio
                      key="rdbPermissionsEmp"
                      id="rdbPermissionsEmp"
                      inline
                      label="Employers"
                      dataQa="datatable-modal-permission-employer"
                      checked={table.completedBy === 'employer'}
                      onChange={() => {
                        handleChange('employer', 'permissions');
                      }}
                    />
                  )}
                  {hasProviderStage && (
                    <Radio
                      key="rdbPermissionsTrn"
                      id="rdbPermissionsTrn"
                      inline
                      dataQa="datatable-modal-permission-trainingprovider"
                      label="Training providers"
                      checked={table.completedBy === 'trainingProvider'}
                      onChange={() => {
                        handleChange('trainingProvider', 'permissions');
                      }}
                    />
                  )}
                </>
              )}

              {table.itemType === 'text' && (
                <>
                  {hasLearnerStage && (
                    <Radio
                      key="rdbVisibleLrn"
                      id="rdbVisibleLrn"
                      inline
                      label="Learners"
                      checked={table.alwaysVisibleTo === 'learner'}
                      onChange={() => {
                        handleChange('learner', 'visible');
                      }}
                    />
                  )}
                  {hasEmployerStage && (
                    <Radio
                      key="rdbVisibleEmp"
                      id="rdbVisibleEmp"
                      inline
                      label="Employers"
                      checked={table.alwaysVisibleTo === 'employer'}
                      onChange={() => {
                        handleChange('employer', 'visible');
                      }}
                    />
                  )}
                  {hasProviderStage && (
                    <Radio
                      key="rdbVisibleTrn"
                      id="rdbVisibleTrn"
                      inline
                      label="Training providers"
                      checked={table.alwaysVisibleTo === 'trainingProvider'}
                      onChange={() => {
                        handleChange('trainingProvider', 'visible');
                      }}
                    />
                  )}
                  <Radio
                    key="rdbVisibleAll"
                    id="rdbVisibleAll"
                    inline
                    label="All"
                    checked={table.alwaysVisibleTo === 'both'}
                    onChange={() => {
                      handleChange('both', 'visible');
                    }}
                  />
                </>
              )}
            </RadioWrapper>
          </Stack>
          {table.itemType === 'input' && (
            <Stack stackMultiplier={0.5}>
              <Label htmlFor="hide-sensitive-info">Hide this table?</Label>
              <Stack stackMultiplier={1}>
                <ModalText>
                  Only the selected user type will be able to see this table.
                </ModalText>
                <Checkbox
                  id="hide-sensitive-info"
                  value="1"
                  inline
                  name="hide-sensitive-info"
                  label="Hide"
                  dataQa="privacy-chk-hide"
                  checked={table.isSensitive}
                  onChange={() => {
                    setTable(
                      produce(table, (newTable) => {
                        newTable.isSensitive = !newTable.isSensitive;
                        return newTable;
                      }),
                    );
                  }}
                />
              </Stack>
            </Stack>
          )}
          <ButtonRow>
            <Button
              dataQa="datatable-modal-back-button"
              variant="subtle"
              onClick={() => {
                onUpdateModalStyle({
                  content: {
                    width: '100%',
                    maxWidth: '100%',
                  },
                });

                setStep(3);
              }}
            >
              Back
            </Button>
            <Button
              dataQa="datatable-modal-continue-button"
              onClick={() => {
                handleSubmit();
              }}
            >
              Continue
            </Button>
          </ButtonRow>
        </Stack>
      )}
    </>
  );
};

export default CreateDataTable;
