import produce from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MaxLength } from '../../../context-providers/enums/global-enums';
import { TFormTextVisibleTo } from '../../../context-providers/forms/form-types';
import {
  getFieldTitle,
  IFormText,
  TFormTextSpec,
} from '../../../context-providers/forms/forms-fields';
import {
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Radio from '../../atoms/form-fields/radio/Radio';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { IconArrowRight } from '../../icons';
import { ModalText, RadioWrapper } from '../ModalContent.style';
import { IWorkflow } from '../../../context-providers/forms/forms-reducer';
import { StageId } from '../../../context-providers/forms/form-workflows';
import RichTextInput from '../../atoms/form-fields/rich-text-input/RichTextInput';

interface IAddFormText {
  formFieldSpec?: TFormTextSpec;
  workflow: IWorkflow;
  currentField?: IFormText;
  index?: number;
}

const AddFormText = ({
  formFieldSpec,
  workflow,
  currentField,
  index,
}: IAddFormText) => {
  const closeModal = useCloseModal();
  const newForm = useNewForm();
  const setNewForm = useSetNewForm();

  const [step, setStep] = useState(0);

  const [field, setField] = useState<IFormText>(
    currentField || {
      id: uuidv4(),
      itemType: 'text',
      inputType: formFieldSpec.type,
      alwaysVisibleTo: 'learner',
      props: {
        text: '',
      },
    },
  );

  const [hasLearnerStage, setHasLearnerStage] = useState<boolean>(false);
  const [hasEmployerStage, setHasEmployerStage] = useState<boolean>(false);
  const [hasProviderStage, setHasProviderStage] = useState<boolean>(false);
  const [hasMoreThenOneStage, setHasMoreThenOneStage] =
    useState<boolean>(false);

  const setPermissions = () => {
    const a = !!workflow.stages.find((x) => x.stageId === StageId.LEARNER);
    const b = !!workflow.stages.find((x) => x.stageId === StageId.EMPLOYER);
    const c = !!workflow.stages.find((x) => x.stageId === StageId.PROVIDER);
    const x = (a && b) || (a && c) || (b && c);
    setHasLearnerStage(a);
    setHasEmployerStage(b);
    setHasProviderStage(c);
    setHasMoreThenOneStage(x);
    if (!x) {
      let value: TFormTextVisibleTo = 'learner';
      if (b) value = 'employer';
      if (c) value = 'trainingProvider';
      handleChange(value, 'alwaysVisibleTo');
    }
  };

  useEffect(() => {
    setPermissions();
  }, []);

  const handleChange = (value: string, fieldName: string) => {
    setField(
      produce(field, (newField) => {
        set(newField, fieldName, value);
        return newField;
      }),
    );
  };

  const updateVisibleTo = (value: TFormTextVisibleTo) => {
    handleChange(value, 'alwaysVisibleTo');
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    let currentFormData = newForm.formData.slice();
    const footerIndex = currentFormData.findIndex(
      (f) => f.inputType === 'Footer',
    );
    const itemIndex = currentFormData.findIndex(
      (f) => f.id === currentField?.id,
    );
    const form = produce(newForm, (d) => {
      if (currentField && index !== undefined) {
        currentFormData.splice(itemIndex, 1, field);
      } else {
        if (footerIndex < 0) {
          currentFormData.push(field);
        } else {
          currentFormData.splice(footerIndex, 0, field);
        }
      }
      d.formData = currentFormData;
      return d;
    });
    setNewForm(form);
    closeModal();
  };

  const isHyperlink = field.inputType === 'Hyperlink';
  const isParagraph = field.inputType === 'Paragraph';
  const isTitle = field.inputType === 'Title';
  const actionType = currentField ? 'Edit' : 'Create';
  const textErr = validateField(field.props.text, {
    required: true,
    maxLength: isParagraph ? MaxLength.Paragraph : MaxLength.Title,
    paragraph: isParagraph,
    title: isTitle,
    hyperlink: isHyperlink,
  });
  const step1Valid = !textErr;

  const replacedText =
    field.inputType === 'Paragraph'
      ? field.props.text.replaceAll('<p></p>', '<br />')
      : field.props.text;

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
    >
      <Stack stackMultiplier={2}>
        {step === 0 && (
          <>
            <h2>
              {actionType} {getFieldTitle(field.inputType)}
            </h2>
            <Stack stackMultiplier={1}>
              <RichTextInput
                as={'html-description'}
                label={field.inputType}
                id="title"
                value={replacedText || ''}
                error={textErr}
                onChangeText={(str) => handleChange(str, 'props.text')}
                dataQa="create-input-title"
              ></RichTextInput>
            </Stack>
            <ButtonRow>
              <Button
                onClick={closeModal}
                variant="secondary"
                dataQa="create-btn-cancel"
              >
                Cancel
              </Button>

              {hasMoreThenOneStage ? (
                <Button
                  onClick={() => setStep(1)}
                  iconRight={<IconArrowRight responsive />}
                  disabled={!step1Valid}
                  dataQa="set-permissions"
                >
                  Set permissions
                </Button>
              ) : (
                <Button disabled={!step1Valid} onClick={() => {}} type="submit">
                  {actionType} field
                </Button>
              )}
            </ButtonRow>
          </>
        )}

        {step === 1 && (
          <>
            <h2>Set Permissions</h2>
            <Stack stackMultiplier={3}>
              <Stack stackMultiplier={0.5}>
                <Stack stackMultiplier={1}>
                  <ModalText>
                    Select who can view this part of the form.
                  </ModalText>
                  <RadioWrapper className="column">
                    {hasLearnerStage && (
                      <Radio
                        key="learner"
                        id="learner"
                        label="Learners"
                        inline
                        checked={field.alwaysVisibleTo === 'learner'}
                        onChange={() => {
                          updateVisibleTo('learner');
                        }}
                      />
                    )}
                    {hasEmployerStage && (
                      <Radio
                        key="employer"
                        id="employer"
                        inline
                        label="Employers"
                        checked={field.alwaysVisibleTo === 'employer'}
                        onChange={() => {
                          updateVisibleTo('employer');
                        }}
                      />
                    )}
                    {hasProviderStage && (
                      <Radio
                        key="trainingProvider"
                        id="trainingProvider"
                        inline
                        label="Training provider"
                        checked={field.alwaysVisibleTo === 'trainingProvider'}
                        onChange={() => {
                          updateVisibleTo('trainingProvider');
                        }}
                      />
                    )}
                    <Radio
                      key="both"
                      id="both"
                      inline
                      label="All"
                      checked={field.alwaysVisibleTo === 'both'}
                      onChange={() => {
                        updateVisibleTo('both');
                      }}
                    />
                  </RadioWrapper>
                </Stack>
              </Stack>
            </Stack>
            <ButtonRow>
              <Button
                onClick={() => setStep(0)}
                variant="secondary"
                dataQa="privacy-btn-back"
              >
                Back
              </Button>
              <Button
                onClick={() => {}}
                type="submit"
                dataQa="privacy-btn-confirm"
              >
                Confirm
              </Button>
            </ButtonRow>
          </>
        )}
      </Stack>
    </form>
  );
};

export default AddFormText;
