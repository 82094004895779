import React from 'react';
import {
  StaticToolTipElement,
  StaticToolTipWrapper,
} from './static-tooltip.style';

interface StaticToolTipProps {
  children: JSX.Element;
  text: string;
}

const StaticTooltip = ({ text, children }: StaticToolTipProps) => {
  return (
    <StaticToolTipWrapper>
      <StaticToolTipElement className="StaticToolTip">
        {text}
      </StaticToolTipElement>
      {children}
    </StaticToolTipWrapper>
  );
};

export default StaticTooltip;
