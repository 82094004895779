import React, { useState } from 'react';
import {
  Editor,
  EditorPasteEvent,
  EditorTools,
  EditorUtils,
  PasteCleanupSettings,
} from '@progress/kendo-react-editor';
import { TInputAttributes } from '../text-input/TextInput.style';
import Stack from '../../stack/Stack';
import Label from '../label/Label';
import {
  LabelText,
  LabelWrapper,
  MandatoryIndicator,
  ShowWhiteSpace,
} from '../../../layout/Layout.style';
import Message from '../../message/Message';

export type TRichTextInput = TInputAttributes & {
  label?: string;
  description?: string;
  error?: string;
  onChangeText: (arg1: string) => void;
  as?: 'html-title' | 'html-description';
  id: string; // forces id input attribute to be required
  dataQa?: string;
};

const RichTextInput = ({
  label,
  description,
  error,
  dataQa,
  onChangeText,
  as = 'html-title',
  onBlur,
  onChange,
  ...inputAttributes
}: TRichTextInput) => {
  const {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignRight,
    AlignCenter,
    FontName,
    FontSize,
    ForeColor,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
  } = EditorTools;

  const {
    pasteCleanup,
    sanitize,
    removeAttribute,
    replaceImageSourcesFromRtf,
  } = EditorUtils;

  const pasteSettings: PasteCleanupSettings = {
    convertMsLists: true,
    attributes: {
      'class': removeAttribute,
      'style': removeAttribute,
      // keep `width`, `height` and `src` attributes
      'width': () => {},
      'height': () => {},
      'src': () => {},
      // Removes `lang` attribute
      // lang: removeAttribute,

      // removes other (unspecified above) attributes
      '*': removeAttribute,
    },
  };
  const [showError, setShowError] = useState(false);

  const errorText = showError ? error : '';

  const handleChange = (html: string) => {
    onChangeText(html);
  };

  const handleBlur = (editorEvent: any) => {
    setShowError(true);
    if (onBlur) onBlur(editorEvent);
  };

  const handlePaste = (event: EditorPasteEvent) => {
    let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
    // If the pasted HTML contains images with sources pointing to the local file system,
    // `replaceImageSourcesFromRtf` will extract the sources from the RTF and place them to images 'src' attribute in base64 format.
    if (event.nativeEvent.clipboardData) {
      html = replaceImageSourcesFromRtf(html, event.nativeEvent.clipboardData);
    }
    return html;
  };

  const inputStyle = as == 'html-title' ? { height: 70 } : { height: 150 };

  return (
    <Stack stackMultiplier={0.5}>
      <Label
        htmlFor={inputAttributes.id}
        dataQa={`form-lbl-${label?.split(' ')[0]}`}
      >
        <LabelWrapper>
          <LabelText>{label}</LabelText>
          <MandatoryIndicator>
            {inputAttributes.required && '*'}
          </MandatoryIndicator>
        </LabelWrapper>
      </Label>
      {description && (
        <ShowWhiteSpace>
          <label
            htmlFor={inputAttributes.id}
            data-qa={`form-desc-${description?.split(' ')[0]}`}
          >
            {description}
          </label>
        </ShowWhiteSpace>
      )}
      <Editor
        contentStyle={inputStyle}
        value={inputAttributes.value?.toString()}
        onChange={(editor) => {
          handleChange(editor.html);
        }}
        onBlur={(editor) => {
          handleBlur(editor.nativeEvent);
        }}
        onPasteHtml={(event) => handlePaste(event)}
        tools={[
          [FontName, FontSize, ForeColor],
          [Bold, Italic, Underline],
          [Undo, Redo],
          [AlignLeft, AlignCenter, AlignRight],
          [OrderedList, UnorderedList, Indent, Outdent],
        ]}
      />
      <Message error text={errorText} />
    </Stack>
  );
};

export default RichTextInput;
