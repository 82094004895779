import React, { ChangeEvent, useState, useRef, ReactElement } from 'react';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';
import {
  IFormImage,
  IFormText,
} from '../../../context-providers/forms/forms-fields';
import {
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import { Box, WidthBox } from '../../atoms/custom-elements/Box.style';
import {
  FileButtonContain,
  FileUploadContain,
  FileUploadMessage,
} from '../../atoms/form-fields/file-upload/FileUpload.style';
import LoaderOverlay from '../../atoms/loader-overlay/LoaderOverlay';
import Message from '../../atoms/message/Message';
import Stack from '../../atoms/stack/Stack';
import { formatBytes } from '../../../utils/file';
import { fetchAPI } from '../../../utils/fetch-api';
import { IconUpload } from '../../icons';
import { FormUploadResponse } from '../../atoms/form-fields/file-upload/FormUploadResponse';
import { Powergraph } from '../../atoms/custom-elements/Powergraph.style';
import { TFormImageType } from '../../../context-providers/forms/form-types';

interface IUploadImage {
  currentField?: IFormText;
  index?: number;
  inputType: TFormImageType;
}

interface IModalContent {
  title: string;
  powergraph: ReactElement<string>;
  btnText: string;
}

const HeaderContent: IModalContent = {
  title: 'Add company logo',
  powergraph: (
    <>
      <Powergraph width="100px">
        Select a logo image to add to the header of your form.
        <br />
        <br />
        Your image must be a JPEG or PNG that’s at least 150 pixels in width and
        no bigger than 25MB.
      </Powergraph>
    </>
  ),
  btnText: 'Add to header',
};

const FooterContent: IModalContent = {
  title: 'Add other logos',
  powergraph: (
    <>
      <Powergraph>
        Select an accreditation or funding logo image to add to the footer of
        your form.
        <br />
        <br />
        Your image must be a JPEG or PNG that’s at least 150 pixels in width and
        no bigger than 25MB.
      </Powergraph>
    </>
  ),
  btnText: 'Add to footer',
};

const UploadImage = ({ currentField, index, inputType }: IUploadImage) => {
  const closeModal = useCloseModal();
  const newForm = useNewForm();
  const setNewForm = useSetNewForm();
  const fileRef = useRef<HTMLInputElement>(null);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState(showError ? 'error' : '');
  const [isLoading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Blob | null>(null);
  const [fileName, setFileName] = useState<ReactElement<string> | string>(
    'No image selected',
  );

  const [field, setField] = useState<IFormImage>({
    id: uuidv4(),
    itemType: 'image',
    inputType,
    alwaysVisibleTo: 'both',
    props: {
      fileId: '',
    },
  });

  const handleSubmit = async () => {
    if (!isLoading) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append('file', selectedImage as Blob);
      const res = await fetchAPI<FormUploadResponse>({
        isFileUpload: true,
        path: `files/uploadformimage`,
        body: formdata,
        method: 'POST',
      });

      if (res.success) {
        const { fileId } = res.body;
        field.props.fileId = fileId;
        setField(field);
        const form = produce(newForm, (d) => {
          if (currentField && index !== undefined) {
            d.formData[index] = field;
          } else if (inputType === 'Footer') {
            d.formData.push(field);
          } else {
            const footerIndex = d.formData.findIndex(
              (f) => f.inputType === 'Footer',
            );
            if (footerIndex < 0) {
              d.formData.push(field);
            } else {
              d.formData.splice(footerIndex, 0, field);
            }
          }
          return d;
        });
        setNewForm(form);
        setLoading(false);
        closeModal();
      }
    }
  };

  const setImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const file = files && files[0];

    if (file) {
      if (file?.size / (1024 * 1024) > 25) {
        setErrorText('File must be smaller than 25mb');
        setFileName('No image selected');
        setShowError(true);
      } else {
        setSelectedImage(file);
        setShowError(false);

        let fileNameString = file.name;
        const shortBy = 20;
        const fileType: string[] = file.type.match(/[^/]*$/g) || [];

        if (fileNameString.length > shortBy)
          fileNameString = `${fileNameString.substr(0, shortBy)}.${
            fileType[0]
          }`;

        const text: ReactElement<string> = (
          <p style={{ fontSize: '14px' }}>
            <strong>{fileNameString}</strong> <br /> ({formatBytes(file.size)})
          </p>
        );
        setFileName(text);
      }
    }
  };

  return (
    <WidthBox width="451px">
      <h1>
        {' '}
        {inputType === 'Header'
          ? HeaderContent.title
          : FooterContent.title}{' '}
      </h1>

      <Box margin="24px 0 24px;">
        {inputType === 'Header'
          ? HeaderContent.powergraph
          : FooterContent.powergraph}
      </Box>
      <Box margin="0 0 33px 0px" border>
        <Box padding="16px 21px 16px 21px">
          <FileUploadContain>
            <FileButtonContain>
              <Button
                disabled={false}
                iconLeft={<IconUpload />}
                onClick={() => {
                  fileRef.current && fileRef.current.click();
                }}
              >
                Select image
              </Button>
            </FileButtonContain>
            <input
              type="file"
              onChange={setImage}
              id={`file-upload-${index}`}
              name="avatar"
              accept=".jpg,.png,.jpeg,"
              ref={fileRef}
            />
            <FileUploadMessage>{fileName}</FileUploadMessage>
          </FileUploadContain>
          {showError && (
            <Box margin="10px 0 0">
              <Message error text={errorText} />
            </Box>
          )}
        </Box>
      </Box>
      <ButtonRow>
        <Button
          onClick={() => {
            closeModal();
          }}
          variant="subtle"
        >
          Cancel
        </Button>
        <Button
          disabled={fileName === 'No image selected' && !isLoading}
          onClick={() => {
            handleSubmit();
          }}
        >
          <LoaderOverlay loading={isLoading}>
            {inputType === 'Header'
              ? HeaderContent.btnText
              : FooterContent.btnText}
          </LoaderOverlay>
        </Button>
      </ButtonRow>
    </WidthBox>
  );
};

export default UploadImage;
