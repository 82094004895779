import produce from 'immer';
import React from 'react';
import { IFormImage } from '../../../context-providers/forms/forms-fields';
import {
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { IconBin } from '../../icons';
import {
  BuildFormField,
  FormFieldActions,
  FormFieldButton,
} from './BuildFormItem.style';
import { fetchAPI } from '../../../utils/fetch-api';
import RenderFormImageFooter from '../Form/RenderFormImageFooter';
import { RowCenter } from '../../layout/Layout.style';
import ConfirmDeleteField from '../../modals/forms/ConfirmDeleteField';

interface IBuildFormItem {
  fields: IFormImage[];
}

const BuildFormFooterItem = ({ fields }: IBuildFormItem) => {
  const showModal = useShowModal();
  const newForm = useNewForm();
  const setNewForm = useSetNewForm();

  const onConfirmDelete = async (field: IFormImage) => {
    const res = await fetchAPI<string>({
      path: 'files/deleteformfile',
      method: 'DELETE',
      responseType: 'text',
      urlParams: {
        fileId: field.props.fileId,
      },
    });

    if (res.success) {
      const form = produce(newForm, (d) => {
        const index = d.formData.findIndex((f) => f.id === field.id);
        d.formData.splice(index, 1);
        return d;
      });
      setNewForm(form);
    }
  };

  return (
    <RowCenter>
      {fields.map((field, index) => (
        <BuildFormField key={field.id} isTextField={false}>
          <FormFieldActions>
            <FormFieldButton
              title="Delete"
              onClick={() => {
                showModal(ConfirmDeleteField, {
                  onConfirmDelete: () => onConfirmDelete(field),
                });
              }}
            >
              <IconBin />
            </FormFieldButton>
          </FormFieldActions>
          <RenderFormImageFooter key={`footer-${index}`} field={field} />
        </BuildFormField>
      ))}
    </RowCenter>
  );
};

export default BuildFormFooterItem;
