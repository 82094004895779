import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  IFormTable,
  ITableDataValue,
} from '../../../context-providers/forms/forms-fields';
import Button from '../../atoms/button/Button';
import Stack from '../../atoms/stack/Stack';
import { color } from '../../config';
import { IconClose, IconError, IconDelete, IconPlus } from '../../icons';
import StaticTooltip from '../../organisms/static-tooltip';
import { CellValidation } from './cell-models/DataTableCell';
import {
  CellDiv,
  CellRow,
  DashedBorder,
  DataTableTextInput,
  DeleteRowColWrapper,
  DeleteTextIconWrapper,
  ErrorMessage,
} from './data-tables.style';
import { DataTableMapper } from './mappers/IDataTableMapper';

interface ICreateDataTableProps {
  dataTable: IFormTable;
  onChange: (dataTable: ICreateDataTableResponse) => void;
}

interface ICreateDataTableResponse {
  dataTable: IFormTable;
  isValid: boolean;
}

const CreateTextFromTable = ({
  dataTable,
  onChange,
}: ICreateDataTableProps) => {
  const [cells, setCells] = useState<CellValidation[][]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const noMoreRows = dataTable.props.rowCount === 100;
  const noMoreColumns = dataTable.props.columnCount === 7;

  const mapCellsToDataTable = (
    newCells: CellValidation[][],
    newDataTable: IFormTable,
  ) => {
    const props = { ...newDataTable.props };

    const dataCells2: ITableDataValue[][] = [];
    const columnHeaders2: string[] = [];
    const rowHeaders2: string[] = [];

    for (let i = 0; i < newCells.length; i++) {
      dataCells2[i] = [];
      for (let j = 0; j < newCells[i].length; j++) {
        const cell = newCells[i][j];
        if (cell.type !== 'Empty') {
          if (cell.cellLocation === 'ColumnHeader') {
            columnHeaders2.push(cell.value);
          } else if (cell.cellLocation === 'RowHeader') {
            rowHeaders2.push(cell.value);
          } else if (cell.cellLocation === 'Data') {
            dataCells2[i].push({
              value: cell.value,
              type:
                newDataTable.itemType === 'input' ? 'TextInput' : 'Paragraph',
            });
          }
        }
      }
    }

    props.data = dataCells2.filter((row) => row.length > 0);
    props.columnHeaders = columnHeaders2;
    props.rowHeaders = rowHeaders2;
    newDataTable.props = props;

    return newDataTable;
  };

  const emitChangeToParent = () => {
    const newDataTable = mapCellsToDataTable(cells, { ...dataTable });

    const isValid = cells.flat().every((cell) => cell.isValid);
    onChange({ dataTable: newDataTable, isValid });
  };

  const updateCell = (cell: CellValidation) => {
    const temp = [...cells];
    for (let i = 0; i < cells.length; i++) {
      for (let j = 0; j < cells[i].length; j++) {
        if (cells[i][j].id === cell.id) {
          temp[i][j] = cell;
          break;
        }
      }
    }
    const distinctErrors = new Set(
      temp
        .flat()
        .filter((c) => !c.isValid && c.isDirty)
        .map((c) => c.error),
    );
    setErrors(Array.from(distinctErrors));
    setCells(temp);
    emitChangeToParent();
  };

  const allocateTableHeaders = (selected: number, props: any, type: string) => {
    const HeadersNew = [];
    const DataNew: ITableDataValue[][] = [];

    for (let i = 0; i < props[type].length; i++) {
      const item = props[type][i];
      if (i !== selected) {
        HeadersNew.push(item);
      }
    }
    let iNew = 0;
    for (let i = 0; i < props.data.length; i++) {
      if (type === 'rowHeaders') {
        if (i !== selected) {
          DataNew[iNew] = [];
          for (let j = 0; j < props.data[i].length; j++) {
            const item = props.data[i][j];
            DataNew[iNew].push(item);
          }
          iNew++;
        }
      } else if (type === 'columnHeaders') {
        DataNew[iNew] = [];
        for (let j = 0; j < props.data[i].length; j++) {
          if (j !== selected) {
            const item = props.data[i][j];
            DataNew[iNew].push(item);
          }
        }
        iNew++;
      }
    }

    return {
      HeadersNew,
      DataNew,
    };
  };

  const deleteTooltip = (cellLocation: string) => {
    let toolText: string;
    if (cellLocation === 'ColumnHeader') {
      toolText = 'Delete column';
    } else {
      toolText = 'Delete row';
    }
    return toolText;
  };

  const deleteColumnsAndRows = (
    dataTableDelete: IFormTable,
    selectedCell: CellValidation,
  ) => {
    if (
      selectedCell.cellLocation === 'ColumnHeader' ||
      selectedCell.cellLocation === 'RowHeader'
    ) {
      let newTable = { ...dataTableDelete };
      const props = { ...dataTableDelete.props };

      if (selectedCell.cellLocation === 'RowHeader' && props.rowCount > 1) {
        const selectedRow = Number(
          selectedCell.id.substring(0, selectedCell.id.indexOf('-')),
        );

        const { HeadersNew, DataNew } = allocateTableHeaders(
          selectedRow,
          props,
          'rowHeaders',
        );

        props.rowHeaders = HeadersNew;
        props.data = DataNew;
        props.rowCount -= 1;
      }

      if (
        selectedCell.cellLocation === 'ColumnHeader' &&
        props.columnCount > 1
      ) {
        const selectedCol = Number(selectedCell.id.charAt(0));
        const { HeadersNew, DataNew } = allocateTableHeaders(
          selectedCol,
          props,
          'columnHeaders',
        );

        props.columnHeaders = HeadersNew;
        props.data = DataNew;
        props.columnCount -= 1;
      }

      newTable.props = props;
      newTable = DataTableMapper.fillHeadersAndData(newTable);

      const mappedCells = DataTableMapper.mapTableForCreation(newTable);
      setCells(mappedCells);

      newTable = mapCellsToDataTable(mappedCells, { ...newTable });
      const isValid = cells.flat().every((cell) => cell.isValid);

      onChange({ dataTable: newTable, isValid });
    }
  };

  const addRow = (dataRowTable: IFormTable) => {
    let newDataTable = { ...dataRowTable };
    const props = { ...newDataTable.props };
    props.rowCount++;
    newDataTable.props = props;
    newDataTable = DataTableMapper.fillHeadersAndData(newDataTable);
    const mappedCells = DataTableMapper.mapTableForCreation(newDataTable);
    setCells(mappedCells);
    newDataTable = mapCellsToDataTable(mappedCells, { ...newDataTable });
    const isValid = cells.flat().every((cell) => cell.isValid);
    onChange({ dataTable: newDataTable, isValid });
  };

  const addColumn = (dataColTable: IFormTable) => {
    let newDataTable = { ...dataColTable };
    const props = { ...newDataTable.props };
    props.columnCount++;

    newDataTable.props = props;
    newDataTable = DataTableMapper.fillHeadersAndData(newDataTable);
    const mappedCells = DataTableMapper.mapTableForCreation(newDataTable);
    setCells(mappedCells);
    newDataTable = mapCellsToDataTable(mappedCells, { ...newDataTable });
    const isValid = cells.flat().every((cell) => cell.isValid);
    onChange({ dataTable: newDataTable, isValid });
  };

  useEffect(() => {
    const mappedCells = DataTableMapper.mapTableForCreation(dataTable);
    setCells(mappedCells);
    const newDataTable = mapCellsToDataTable(mappedCells, { ...dataTable });
    onChange({
      dataTable: newDataTable,
      isValid: mappedCells.flat().every((c) => c.isValid),
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCell = (
    cell: CellValidation,
    rowIndex: number,
    cellIndex: number,
  ) => {
    if (cell.type === 'TextInput') {
      return (
        <CellDiv
          key={`${rowIndex}-${cellIndex}`}
          className="cell"
          hasBackground={cell.cellLocation !== 'Data'}
        >
          <label htmlFor={cell.id} className="sr-only">
            {cell.id}
          </label>
          <DataTableTextInput
            id={cell.id}
            data-qa={'datatable-input-' + cell.id}
            error={cell.isDirty && !cell.isValid}
            isDeleteVisible={
              (cell.cellLocation === 'ColumnHeader' &&
                dataTable.props.columnCount > 1) ||
              (cell.cellLocation === 'RowHeader' &&
                dataTable.props.rowCount > 1)
            }
            value={cell.value}
            onBlur={() => {
              cell.setDirty();
              updateCell(cell);
            }}
            onChange={(e) => {
              cell.setValue(e.currentTarget.value);
              updateCell(cell);
            }}
          />
          {cell.value && (
            <DeleteTextIconWrapper
              isDeleteVisible={
                (cell.cellLocation === 'ColumnHeader' &&
                  dataTable.props.columnCount > 1) ||
                (cell.cellLocation === 'RowHeader' &&
                  dataTable.props.rowCount > 1)
              }
              aria-label="Clear cell"
              onClick={() => {
                cell.setValue('');
                updateCell(cell);
              }}
              moveLeft={cell.isDirty && !cell.isValid}
            >
              <IconClose />
            </DeleteTextIconWrapper>
          )}
          {cell.isDirty && !cell.isValid && (
            <div className="ErrorIconWrapper">
              <IconError fill={color.error.foreground} />
            </div>
          )}

          {((cell.cellLocation === 'ColumnHeader' &&
            dataTable.props.columnCount > 1) ||
            (cell.cellLocation === 'RowHeader' &&
              dataTable.props.rowCount > 1)) && (
            <StaticTooltip text={deleteTooltip(cell.cellLocation)}>
              <DeleteRowColWrapper
                aria-label={deleteTooltip(cell.cellLocation)}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  deleteColumnsAndRows(dataTable, cell);
                }}
              >
                <IconDelete />
              </DeleteRowColWrapper>
            </StaticTooltip>
          )}
        </CellDiv>
      );
    }

    if (cell.type === 'Paragraph') {
      return (
        <CellDiv
          key={`${rowIndex}-${cellIndex}`}
          className="cell"
          hasBackground={cell.cellLocation !== 'Data'}
        >
          <p>{cell.value}</p>
        </CellDiv>
      );
    }

    if (cell.type === 'Empty') {
      return (
        <CellDiv
          className="cell"
          key={`${rowIndex}-${cellIndex}`}
          hasBackground={cell.cellLocation !== 'Data'}
        />
      );
    }

    return null;
  };

  return (
    <Stack stackMultiplier={0.5}>
      <DashedBorder tabIndex={0}>
        {cells.map((row, rowIndex) => {
          return (
            <div
              key={`${rowIndex}-RowCol`}
              className="row"
              style={{ display: 'flex' }}
            >
              <CellRow key={rowIndex} style={{ display: 'flex' }}>
                {row.map((cell, cellIndex) => {
                  return renderCell(cell, rowIndex, cellIndex);
                })}
              </CellRow>
              {rowIndex === 0 && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: '3rem',
                    paddingRight: '3rem',
                    flexShrink: 0,
                  }}
                >
                  <Button
                    key="addColumn"
                    variant="secondary"
                    iconLeft={<IconPlus />}
                    onClick={() => {
                      addColumn(dataTable);
                    }}
                    disabled={noMoreColumns}
                    dataQa="datatable-modal-addnewcolumn-button"
                  >
                    Add Column
                  </Button>
                </div>
              )}
            </div>
          );
        })}
        <div style={{ padding: '2rem' }}>
          <Button
            key="addRow"
            dataQa="datatable-modal-addnewrow-button"
            variant="secondary"
            iconLeft={<IconPlus />}
            onClick={() => {
              addRow(dataTable);
            }}
            disabled={noMoreRows}
          >
            Add Row
          </Button>
        </div>
      </DashedBorder>
      <Stack stackMultiplier={0}>
        {errors.map((error, index) => {
          return (
            <ErrorMessage key={index}>
              <IconError height={20} width={20} />
              {error}
            </ErrorMessage>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default CreateTextFromTable;
