import { navigate } from 'gatsby';
import React, { SyntheticEvent } from 'react';
import ReactGA from '../../../utils/google-analytics';
import {
  useClearNewForm,
  useEditForm,
} from '../../../context-providers/forms/forms-hooks';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import { ModalText } from '../ModalContent.style';

const Publish = () => {
  const closeModal = useCloseModal();
  const clearNewForm = useClearNewForm();

  const saveForm = useEditForm();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const res = await saveForm('published');
    if (res.success) {
      clearNewForm();
      closeModal();
      navigate('/list-forms/');
    }
  };

  return (
    <form
      style={{ maxWidth: 383 }}
      onSubmit={(event) => {
        ReactGA.event({
          category: 'Button',
          action: 'Publish form modal - Publish',
        });
        handleSubmit(event);
      }}
    >
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={0.5}>
          <h2>Publish form template</h2>
        </Stack>
        <ModalText>
          Once a form template is published it cannot be edited again but it may
          be duplicated.
          <br />
          <br />
          Once you’ve published this form, you’ll be able to assign it to a
          learner. This can be done on the ‘form templates’ section.
        </ModalText>
        <ButtonRow>
          <Button
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Publish form modal - Cancel',
              });
              closeModal();
            }}
            variant="subtle"
            dataQa="publishForm-btn-cancel"
          >
            Cancel
          </Button>
          <Button type="submit" dataQa="publishForm-btn-submit">
            Publish
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default Publish;
