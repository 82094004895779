import React, { useEffect, useState } from 'react';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import Icon from '../../icons/Icon';
import { FormFieldButtons } from './CreateFormField.style';
import {
  IFormFieldPrePop,
  TFormPrePopFieldSpec,
  blankImageInserts,
  blankPrePopLearnerInputs,
} from '../../../context-providers/forms/forms-fields';
import Stack from '../../atoms/stack/Stack';
import { useNewForm } from '../../../context-providers/forms/forms-hooks';
import Message from '../../atoms/message/Message';
import { LineBreak } from '../../atoms/custom-elements/LineBreak';
import { validateMaxFields } from '../../../context-providers/forms/forms-validation';
import FormTabs from '../../atoms/tabbed/FormTabs';
import FieldsBlank from './FieldsBlank';
import FieldsPrePopulated from './FieldsPrePopulated';
import configLoader from '../configuration/configuration-loader';
import FormWorkflowUtil from '../../../utils/form-workflow-util';
import { getQueryVar } from '../../../utils/query-vars';
import { IWorkflow } from '../../../context-providers/forms/forms-reducer';
import UploadImage from '../../modals/forms/UploadImage';
import { StageId } from '../../../context-providers/forms/form-workflows';
import { v4 as uuidv4 } from 'uuid';
const CreateFormField = () => {
  const [headerToggle, setHeaderToggle] = useState<boolean>(false);
  const showModal = useShowModal();
  const newForm = useNewForm();
  const hasMaxFields = !validateMaxFields(newForm);
  const config = configLoader();
  const workflowId = getQueryVar('workflowId');
  const [workflow, setWorkflow] = useState<IWorkflow>();
  const [showPrePop, setShowPrepop] = useState<boolean>(false);
  const [workflowLoaded, setWorkflowLoaded] = useState<boolean>(false);
  const [hasEnquiryStage, setHasEnquiryStage] = useState<boolean>(false);

  const getWorkflow = () => {
    if (workflowLoaded || !workflowId) return;
    FormWorkflowUtil.getWorkflow(workflowId).then((workflow) => {
      setWorkflow(workflow);
      let hasEmployerStage =
        workflow.stages.findIndex((x) => x.stageId === StageId.EMPLOYER) > -1
          ? true
          : false;
      let hasLearnerStage =
        workflow.stages.findIndex((x) => x.stageId === StageId.LEARNER) > -1
          ? true
          : false;
      let hasEnquiryStage =
        workflow.stages.findIndex((x) => x.stageId === StageId.ENQUIRY) > -1
          ? true
          : false;
      setHasEnquiryStage(hasEnquiryStage);
      setShowPrepop(
        (hasEmployerStage || hasLearnerStage || hasEnquiryStage) &&
          config.FeatureFlags.prePopulated,
      );
      if (hasEnquiryStage && newForm.id === undefined && !newForm.isDuplicate) {
        newForm.formData = [];
        blankPrePopLearnerInputs
          .filter((x) => x.enquiryDefault)
          .map((x: TFormPrePopFieldSpec) => {
            let prePop: IFormFieldPrePop = {
              id: uuidv4(),
              itemType: 'prepop',
              inputType: x.type,
              isSensitive: false,
              completedBy: 'learner',
              props: {
                label: x.label,
                description: '',
                options: undefined,
                originalLabel: x.label,
              },
              validation: {
                required: true,
              },
              default: true,
            };
            newForm.formData.push(prePop);
          });
      }
      setWorkflowLoaded(true);
    });
  };

  useEffect(() => {
    getWorkflow();
  }, [workflowLoaded]);

  useEffect(() => {
    setHeaderToggle(
      !!newForm.formData.find((i) => {
        return i.inputType === 'Header';
      }),
    );
  }, [newForm]);

  return (
    <Stack stackMultiplier={2}>
      <Stack stackMultiplier={1}>
        <h3>Add Header</h3>
        <p>Customise the header section at the top of your form.</p>
      </Stack>
      <Message
        error
        text={hasMaxFields ? 'Maximum number of form items reached.' : ''}
      />

      <FormFieldButtons>
        {blankImageInserts
          .filter((i) => i.itemType === 'Header')
          .map((item, i) => {
            return (
              <button
                type="button"
                data-qa={`form-btn-${item.itemType}`}
                key={`${item.type}-${i}`}
                onClick={() => {
                  showModal(UploadImage, {
                    inputType: 'Header',
                  });
                }}
                disabled={headerToggle}
              >
                <Icon type={item.icon} />
                <span>{item.label}</span>
              </button>
            );
          })}
      </FormFieldButtons>
      <LineBreak margin="32px 0 ​0 0" />

      {showPrePop ? (
        <FormTabs
          tabs={[
            {
              icon: 'IconPrePop',
              title: 'Pre-Populated fields',
              slug: 'pre-populated-fields',
              component: FieldsPrePopulated,
            },
            {
              title: 'Create your own',
              slug: 'create-your-own-fields',
              component: () =>
                FieldsBlank({
                  workflow: workflow!,
                  isEnquiry: hasEnquiryStage,
                }),
            },
          ]}
        />
      ) : (
        <Stack>
          <h3>Add your own fields</h3>
          <FieldsBlank
            workflow={workflow!}
            isEnquiry={hasEnquiryStage}
          ></FieldsBlank>
        </Stack>
      )}

      <LineBreak margin="32px 0 ​0 0" />

      <Stack stackMultiplier={1}>
        <h3>Add Footer</h3>
        <p>Customise the footer section at the bottom of your form.</p>
      </Stack>

      <FormFieldButtons>
        {blankImageInserts
          .filter((i) => i.itemType === 'Footer')
          .map((item, i) => {
            return (
              <button
                type="button"
                data-qa={`form-btn-${item.itemType}`}
                key={`${item.type}-${i}`}
                onClick={() => {
                  showModal(UploadImage, {
                    inputType: 'Footer',
                  });
                }}
                disabled={item.isDisabled || hasMaxFields}
              >
                <Icon type={item.icon} />
                <span>{item.label}</span>
              </button>
            );
          })}
      </FormFieldButtons>
    </Stack>
  );
};

export default CreateFormField;
