import React, { useEffect, useState } from 'react';
import buildFormAutoSaveSubject from '../../../context-providers/build-form-autosave/BuildFormAutoSaveSubject';
import { IFormImage } from '../../../context-providers/forms/forms-fields';
import { useNewForm } from '../../../context-providers/forms/forms-hooks';
import { IFormTemplate } from '../../../context-providers/forms/forms-reducer';
import {
  useShowModal,
  useEditFormName,
} from '../../../context-providers/ui/ui-hooks';
import Stack from '../../atoms/stack/Stack';
import EditableTitle from '../../atoms/title/EditableTitle';
import { LightContentBox } from '../../layout/Layout.style';
import { NewFormFieldNotice } from './BuildForm.style';
import BuildFormHeaderItem from './BuildFormHeaderItem';
import BuildFormItem from './BuildFormItem';
import ReferenceDataHelper from '../../../utils/reference-data-helper';
import {
  ILearnerEthnicity,
  ILearnerLLDD,
  ILearnerSex,
} from '../../../context-providers/learner/learner-reducer';
import EditInitialFormName from '../../modals/forms/EditInitialFormName';
import EditFormName from '../../modals/forms/EditFormName';
import BuildFormFooterItem from './BuildFormFooterItem';

const BuildFormInner = () => {
  const formTemplate = useNewForm();

  const showModal = useShowModal();
  const editFormNameModal = useEditFormName();

  const isNewForm: boolean = formTemplate.status === 'unsaved';

  useEffect(() => {
    return () => {
      buildFormAutoSaveSubject.timer.destroyTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [lldds, setLldds] = useState<ILearnerLLDD[]>([]);
  const [llddsLoaded, setLlddsLoaded] = useState<boolean>(false);

  const [ethnicities, setEthnicities] = useState<ILearnerEthnicity[]>([]);
  const [ethnicitiesLoaded, setEthnicitiesLoaded] = useState<boolean>(false);

  const [sex, setSex] = useState<ILearnerSex[]>([]);
  const [sexLoaded, setSexLoaded] = useState<boolean>(false);

  const loadLLDDs = (): void => {
    if (!llddsLoaded) {
      ReferenceDataHelper.getLllds().then((response) => {
        setLldds(response);
        setLlddsLoaded(true);
      });
    }
  };

  const loadEthnicities = (): void => {
    if (!ethnicitiesLoaded) {
      ReferenceDataHelper.getEthnicities().then((response) => {
        setEthnicities(response);
        setEthnicitiesLoaded(true);
      });
    }
  };

  const loadSex = (): void => {
    if (!sexLoaded) {
      ReferenceDataHelper.getSex().then((response) => {
        setSex(response);
        setSexLoaded(true);
      });
    }
  };

  useEffect(() => {
    loadSex();
  }, [sexLoaded]);

  useEffect(() => {
    loadEthnicities();
  }, [ethnicitiesLoaded]);

  useEffect(() => {
    loadLLDDs();
  }, [llddsLoaded]);

  const header = formTemplate.formData.filter(
    (f) => f.inputType === 'Header',
  ) as IFormImage[];
  const footers = formTemplate.formData.filter(
    (f) => f.inputType === 'Footer',
  ) as IFormImage[];

  const data = formTemplate.formData.filter((f) => f.itemType !== 'image');

  const inputOnlyForm: IFormTemplate = {
    ...formTemplate,
    formData: data || [],
  };

  const showEditInitialTitleModal = () => {
    if (isNewForm) {
      editFormNameModal();
      showModal(EditInitialFormName);
    }
  };

  useEffect(() => {
    showEditInitialTitleModal();
  }, []);

  return (
    <Stack stackMultiplier={2}>
      <EditableTitle
        title={formTemplate.formName}
        data-qa="form-lbl-name"
        onEdit={() => showModal(EditFormName)}
        showSavingPill={formTemplate?.formData?.length > 0}
      />
      <LightContentBox>
        <Stack stackMultiplier={1}>
          <BuildFormHeaderItem fields={header} />
          {inputOnlyForm.formData.map((field, i) => (
            <BuildFormItem
              key={field.id}
              index={i}
              field={field}
              inputOnlyForm={inputOnlyForm}
              sexOptions={sex}
              llddOptions={lldds}
              ethnicityOptions={ethnicities}
            />
          ))}
          <NewFormFieldNotice>
            New form field will be placed here
          </NewFormFieldNotice>
          <BuildFormFooterItem fields={footers} />
        </Stack>
      </LightContentBox>
    </Stack>
  );
};

export default BuildFormInner;
