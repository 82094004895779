import React from 'react';
import ReactTooltip from 'react-tooltip';
import { getQueryVar, urlWithSetQueryVar } from '../../../utils/query-vars';
import GridFlex from '../grid-flex/GridFlex';
import { TabLink, TabLinks } from './FormTabs.style';
import { color } from '../../config';
import Icon, { TIconType } from '../../icons/Icon';
import { IFormWorkflow } from '../../../utils/form-workflow-util';

export interface ITab {
  slug: string;
  title: string;
  component: React.FC<{ workflow?: IFormWorkflow }>;
  dataQaTab?: string;
  icon?: TIconType;
}

interface IFormTabs {
  tabs: ITab[];
  workflow?: IFormWorkflow;
}

const FormTabs = ({ tabs, workflow }: IFormTabs) => {
  const currentTab = getQueryVar('tab');
  const currentTabSpec = tabs.find((tab) => tab.slug === currentTab) || tabs[0];
  const ActiveComponent = currentTabSpec?.component;

  return (
    <>
      <ReactTooltip effect="solid" backgroundColor={color.grey.base} />
      <GridFlex align="flex-end">
        <TabLinks>
          <div style={{ display: 'inline-block' }} role="tablist">
            {tabs.map(({ slug, title, dataQaTab, icon }) => {
              return (
                <TabLink
                  key={slug}
                  role="tab"
                  aria-selected={currentTabSpec.slug === slug}
                  to={urlWithSetQueryVar('tab', slug)}
                  data-qa={dataQaTab}
                >
                  {icon && <Icon type={icon} responsive />}

                  {title}
                </TabLink>
              );
            })}
          </div>
        </TabLinks>
      </GridFlex>
      {ActiveComponent && <ActiveComponent workflow={workflow} />}
    </>
  );
};

export default FormTabs;
