import produce from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  IFormFieldPrePop,
  TFormPrePopFieldSpec,
} from '../../../context-providers/forms/forms-fields';
import {
  useNewForm,
  useSetNewForm,
} from '../../../context-providers/forms/forms-hooks';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Checkbox from '../../atoms/form-fields/checkbox/Checkbox';
import Label from '../../atoms/form-fields/label/Label';
import Radio from '../../atoms/form-fields/radio/Radio';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { IconArrowRight } from '../../icons';
import { ModalText, RadioWrapper } from '../ModalContent.style';
interface IAddFormFieldPrePop {
  formFieldSpec?: TFormPrePopFieldSpec;
  formCompletedBy?: string;
  currentField?: IFormFieldPrePop;
  index?: number;
  options?: string[] | undefined;
  hasLearnerStage: boolean;
  hasEmployerStage: boolean;
  isDefault?: boolean;
}

const AddFormFieldPrePop = ({
  formFieldSpec,
  formCompletedBy,
  currentField,
  index,
  options,
  hasLearnerStage,
  hasEmployerStage,
  isDefault,
}: IAddFormFieldPrePop) => {
  const closeModal = useCloseModal();
  const newForm = useNewForm();

  const setNewForm = useSetNewForm();
  const [step, setStep] = useState(0);

  const [field, setField] = useState<IFormFieldPrePop>(
    currentField || {
      id: uuidv4(),
      itemType: 'prepop',
      inputType: formFieldSpec.type,
      isSensitive: false,
      completedBy: formCompletedBy,
      props: {
        label: formFieldSpec.label,
        description: '',
        options: formFieldSpec.hasOptions ? options : undefined,
        originalLabel: formFieldSpec.label,
      },
      validation: {
        required: true,
      },
      default: isDefault,
    },
  );

  const descriptionErr = validateField(field.props.description, {
    description: true,
  });

  const fieldOptions = field.props.options
    ? field.props.options.join('\n')
    : '';

  const hasOptions = formFieldSpec ? formFieldSpec.hasOptions : false;
  const step1Valid = !descriptionErr;
  const actionType = currentField ? 'Edit' : 'Add';

  const handleChange = (
    value: string[] | string | boolean,
    fieldName: string,
  ) => {
    setField(
      produce(field, (newField) => {
        set(newField, fieldName, value);
        return newField;
      }),
    );
  };

  const handleOptionsChange = (value: string, fieldName: string) => {
    handleChange(value.split(/\r?\n/), fieldName);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    let currentFormData = newForm.formData.slice();
    const footerIndex = currentFormData.findIndex(
      (f) => f.inputType === 'Footer',
    );
    const itemIndex = currentFormData.findIndex(
      (f) => f.id === currentField?.id,
    );
    const form = produce(newForm, (d) => {
      if (currentField && index !== undefined) {
        currentFormData.splice(itemIndex, 1, field);
      } else {
        if (footerIndex < 0) {
          currentFormData.push(field);
        } else {
          currentFormData.splice(footerIndex, 0, field);
        }
      }
      d.formData = currentFormData;
      return d;
    });
    setNewForm(form);
    closeModal();
  };

  return (
    <form
      style={{ maxWidth: 383 }}
      onSubmit={(event) => {
        handleSubmit(event);
      }}
    >
      <Stack stackMultiplier={2}>
        {step === 0 && (
          <>
            <h2>{actionType} pre-populated field</h2>
            <Stack stackMultiplier={1}>
              <TextInput
                label="Title"
                id="title"
                description="Field title is used across records. Keep this consistent."
                value={field.props.label || ''}
                disabled
              />
              <TextInput
                as="textarea"
                label="Description"
                id="description"
                description="This is the text that appears under the title (Optional)."
                dataQa="create-input-desc"
                value={field.props.description || ''}
                error={descriptionErr}
                onChangeText={(str) => handleChange(str, 'props.description')}
              />
              {hasOptions && (
                <div style={{ whiteSpace: 'pre' }}>
                  <TextInput
                    as={'textarea'}
                    label={'Options'}
                    id="options"
                    description={'These are the options for ' + field.inputType}
                    dataQa="create-input-options"
                    value={fieldOptions}
                    onChangeText={(str) =>
                      handleOptionsChange(str, 'props.options')
                    }
                  />
                </div>
              )}
              {!isDefault && (
                <Checkbox
                  id="make-field-optional"
                  value="1"
                  inline
                  name="make-field-optional"
                  label="Make this field optional"
                  dataQa="create-chk-optional"
                  checked={!field.validation.required}
                  disabled={isDefault}
                  onChange={() => {
                    handleChange(
                      !field.validation.required,
                      'validation.required',
                    );
                  }}
                />
              )}
            </Stack>

            <ButtonRow>
              <Button
                onClick={closeModal}
                variant="secondary"
                dataQa="create-btn-cancel"
              >
                Cancel
              </Button>
              {isDefault && (
                <Button
                  onClick={() => {}}
                  type="submit"
                  dataQa="prepop-settings-btn-submit"
                >
                  Add form field
                </Button>
              )}
              {!isDefault && (
                <Button
                  onClick={() => {
                    handleChange(field.props.options ?? [], 'props.options');
                    setStep(1);
                  }}
                  dataQa="create-btn-permissions"
                  iconRight={<IconArrowRight responsive />}
                  disabled={!step1Valid}
                >
                  Set permissions
                </Button>
              )}
            </ButtonRow>
          </>
        )}

        {step === 1 && (
          <>
            <h2>{field.props.label} field settings</h2>
            <Stack stackMultiplier={1}>
              <Stack stackMultiplier={0.5}>
                <Label htmlFor="learner">This field can be completed by</Label>
                <Stack stackMultiplier={1}>
                  <ModalText>
                    This pre-populated field will be completed by either
                    learners or employers. You cannot change it.
                  </ModalText>
                  <RadioWrapper className="column">
                    {hasLearnerStage && (
                      <Radio
                        key="learner"
                        id="learner"
                        label="Learners"
                        dataQa="setModal-radio-learner"
                        inline
                        checked={field.completedBy === 'learner'}
                        disabled
                      />
                    )}
                    {hasEmployerStage && (
                      <Radio
                        key="employer"
                        id="employer"
                        inline
                        label="Employers"
                        dataQa="setModal-radio-employer"
                        checked={field.completedBy === 'employer'}
                        disabled
                      />
                    )}
                  </RadioWrapper>
                </Stack>
              </Stack>
              <Stack stackMultiplier={0.5}>
                <Label htmlFor="hide-sensitive-info">
                  Do you want to hide this field?
                </Label>
                <Stack stackMultiplier={1}>
                  <ModalText>
                    Check the box below to hide this field from users who
                    haven't been selected to complete it.
                  </ModalText>
                  <Checkbox
                    id="hide-sensitive-info"
                    value="1"
                    inline
                    name="hide-sensitive-info"
                    label="Hide"
                    dataQa="prepop-settings-chk-hide"
                    checked={field.isSensitive}
                    onChange={() => {
                      handleChange(!field.isSensitive, 'isSensitive');
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            <ButtonRow>
              <Button
                onClick={() => setStep(0)}
                variant="secondary"
                dataQa="prepop-settings-btn-back"
              >
                Back
              </Button>
              <Button
                onClick={() => {}}
                type="submit"
                dataQa="prepop-settings-btn-submit"
              >
                Add form field
              </Button>
            </ButtonRow>
          </>
        )}
      </Stack>
    </form>
  );
};

export default AddFormFieldPrePop;
