import React from 'react';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { ButtonRowMobile } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import { IconBin, IconClose, IconDelete } from '../../icons';
import {
  CloseIconButton,
  CombinedHeaderLeftRightIcon,
} from '../ModalContent.style';

interface IConfirmDeleteField {
  onConfirmDelete: () => void;
}

const ConfirmDeleteField = ({ onConfirmDelete }: IConfirmDeleteField) => {
  const closeModal = useCloseModal();
  return (
    <Stack stackMultiplier={2}>
      <CombinedHeaderLeftRightIcon>
        <div>
          <IconDelete width={25} height={25} />
          <h2>Delete field</h2>
        </div>
        <CloseIconButton
          aria-label="{getModalTitle()}"
          data-qa="deleteModal-btn-cancel-icon"
          onKeyDown={(e) => e.key === 'Escape' && closeModal()}
          tabIndex={0}
          onClick={() => closeModal()}
        >
          <IconClose width={25} height={25} />
        </CloseIconButton>
      </CombinedHeaderLeftRightIcon>
      <p>Fields can't be recovered once they've been deleted.</p>
      <ButtonRowMobile>
        <Button
          onClick={() => {
            closeModal();
          }}
          variant="secondary"
          dataQa="deleteModal-btn-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirmDelete();
            closeModal();
          }}
          variant="primary"
          iconLeft={<IconBin />}
          dataQa="deleteModal-btn-delete"
        >
          Delete
        </Button>
      </ButtonRowMobile>
    </Stack>
  );
};

export default ConfirmDeleteField;
